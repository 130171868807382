import {Action} from "redux";

export const FOCUS_ON_DOCUMENT_TYPE = "FOCUS_ON_DOCUMENT";

export interface IFocusOnDocumentAction extends Action<string> {
  document: string;
}

export function focusOnDocument(document: string): IFocusOnDocumentAction {
  return {
    document,
    type: FOCUS_ON_DOCUMENT_TYPE,
  };
}
