import {
  IWidgetConfigurationProps,
  withWidgetConfiguration,
} from "@sgwt-widget/core";
import { Component, h } from "preact";
import { connect } from "preact-redux";
import { Action } from "redux";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { buildDocumentUrl } from "../../../common/services/DocumentService";
import { readDocument } from "../../actions/IDocumentAction";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import {ITransaction} from "../../../common/domain/ITransaction";

interface IPreviousButtonState {
  enabled: boolean;
}

export interface IPreviousButtonProps {
  documents: ITransactionDocument[];
  currentDocumentIndex: number;
  transaction: ITransaction;
  readDocument: (documentId: number, documentUrl: string) => void;
}

const PreviousButton = withWidgetConfiguration(
  class extends Component<
    IPreviousButtonProps & IWidgetConfigurationProps,
    IPreviousButtonState
  > {
    public async componentWillMount() {
      this.setState({ enabled: false });
    }

    public async componentDidMount() {
      this.isButtonEnabled(this.props);
    }

    public async componentWillReceiveProps(nextProps: IPreviousButtonProps) {
      this.isButtonEnabled(nextProps);
    }

    public isButtonEnabled(props: IPreviousButtonProps) {
      this.setState({ enabled: props.currentDocumentIndex > 0 });
    }

    public async loadPreviousDocument() {
      const previousDoc = this.props.documents[
        this.props.currentDocumentIndex - 1
      ];
      this.props.readDocument(
        previousDoc.id,
        await buildDocumentUrl(
          this.props.widgetConfiguration,
          this.props.transaction.id,
          previousDoc.id
        )
      );
    }

    public render(): JSX.Element {
      return (
        <button
          disabled={!this.state.enabled}
          className={css("btn btn-lg btn-flat-primary")}
          onClick={() => this.loadPreviousDocument()}
        >
          <em className="material-icons">arrow_back</em>
        </button>
      );
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    currentDocumentIndex: (() => {
      if (state.documentState.currentDocumentId) {
        return state.documentState.documents
          .map((doc: ITransactionDocument) => doc.id)
          .indexOf(state.documentState.currentDocumentId);
      }
      return 0;
    })(),
    documents: state.documentState.documents,
    transaction: state.transactionState.transaction,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  readDocument: (documentId: number, documentUrl: string) =>
    dispatch(readDocument(documentId, documentUrl)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviousButton as any);
