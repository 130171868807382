import { IWidgetConfigurationProps, withWidgetConfiguration } from "@sgwt-widget/core";
import { Component, h } from "preact";
import { ITransaction } from "../../../common/domain/ITransaction";
import { ApplicationState } from "../../states/ApplicationState";
import { Action } from "redux";
import { connect } from "preact-redux";
import { css } from "../../styles";
import { getDocumentsForSignature } from "../../../common/services/SignatureWorkflow";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { IDocumentConsent } from "../../../common/domain/ISignatureWorkflow";
import { dispatchDownloadStatus } from "../../actions/IDocumentAction";
import { DocumentDownloadStatus } from "../../../common/domain/DocumentDownloadStatus";
import { allDocumentsRead } from "../../actions/ISignatureAction";
import { buildDocumentUrl } from "../../../common/services/DocumentService";

export interface IWorkflowDownloadDocumentListProps {
  transaction: ITransaction;
  allDocumentsAreRead: (areRead: boolean) => void;
  dispatchDownloadStatus: (downloadStatus: DocumentDownloadStatus) => void;
}

const WorkflowDownloadDocumentList = withWidgetConfiguration(
  class extends Component<IWorkflowDownloadDocumentListProps & IWidgetConfigurationProps> {
    public documentsConsentArray: IDocumentConsent[] = [];

    private convertFileContentStringIntoUint8Array(fileContent: string) {
      const binaryString = window.atob(fileContent);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }

    private async getPDFBase64(url: string) {
      const response = await this.props.widgetConfiguration.fetch(url);
      const data = await response.arrayBuffer();
      const base64 = btoa(
          new Uint8Array(data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
      return base64;
  }

    private async downloadDocument(file: ITransactionDocument) {
      this.props.dispatchDownloadStatus(DocumentDownloadStatus.DOWNLOADING);
      try {
        const url = await buildDocumentUrl(
          this.props.widgetConfiguration,
          this.props.transaction.id,
          file.id
        );
        await this.getPDFBase64(url)
          .then((filecontent: any) => {
            const arrayData = this.convertFileContentStringIntoUint8Array(filecontent);
            const blob = new Blob([arrayData], { type: file.mimeType });
    
            if (window.navigator && window.navigator.msSaveBlob) {
              window.navigator.msSaveBlob(blob, file.filename);
            } else {
              const link = document.createElement('a');
              link.href = (window.URL || window.webkitURL).createObjectURL(blob);
              link.download = file.filename;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
  
            this.props.dispatchDownloadStatus(DocumentDownloadStatus.DOWNLOADED);
          });
      } catch(e) {
        console.log(e)
        this.props.dispatchDownloadStatus(DocumentDownloadStatus.ERROR);
      };
    }

    public renderDownloadButton(document: ITransactionDocument): JSX.Element {
      return (
        <article>
          <button
            className={`${css("btn btn-lg btn-flat-primary")} not-mobile-article`}
            onClick={() => this.downloadDocument(document)}
          >
            <em className={`material-icons`}>file_download</em>
          </button>
          <button
            className={`${css("btn btn-md btn-flat-primary")} mobile-article`}
            onClick={() => this.downloadDocument(document)}
          >
            <em className={`material-icons`}>file_download</em>
          </button>
        </article>
      );
    }

    private renderDocumentElement(document: ITransactionDocument): JSX.Element {
      return (
        <article class={css("col-12 d-flex")}>
          <section class={css("col-xs-10 col-sm-6 col-md-6 col-lg-6 col-xl-6 d-flex align-items-center justify-content-end")}>
            <label class={"mb-0"}>{document.filename}</label>
          </section>
          <section class={css("col-auto d-flex align-items-center justify-content-start")}>
            {this.renderDownloadButton(document)}
          </section>
        </article>
      )
    }

    public render(): JSX.Element {
      const downloadDocuments: JSX.Element[] = [];
      const documents = getDocumentsForSignature(this.props.transaction);
      documents.forEach(document => {
        downloadDocuments.push(this.renderDocumentElement(document))
      });
      this.props.allDocumentsAreRead(true);
      return (
        <section class={css("row mt-5 mb-5")}>
          {downloadDocuments}
        </section>
      );
    }
  });

function mapStateToProps(state: ApplicationState) {
  return {
    transaction: state.transactionState.transaction,
  }
};

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  dispatchDownloadStatus: (downloadStatus: DocumentDownloadStatus) =>
      dispatch(dispatchDownloadStatus(downloadStatus)),
  allDocumentsAreRead: (areRead: boolean) => dispatch(allDocumentsRead(areRead)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowDownloadDocumentList as any);