import "./zoom.scss";

import { Component, h } from "preact";
import { connect } from "preact-redux";
import { Action } from "redux";
import { changeScale } from "../../actions/IDocumentAction";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";

interface IZoomComponentState {
  scale: number;
}

export interface IZoomComponentProps {
  scale: number;
  changeScale: (scale: number) => void;
}

class ZoomComponent extends Component<
  IZoomComponentProps,
  IZoomComponentState
> {
  public async componentDidMount() {
    this.setState({
      scale: this.props.scale,
    });
  }

  public async componentWillReceiveProps(nextProps: IZoomComponentProps) {
    this.setState({
      scale: nextProps.scale,
    });
  }

  public zoomOut() {
    this.props.changeScale(this.state.scale - 0.1);
  }

  public zoomIn() {
    this.props.changeScale(this.state.scale + 0.1);
  }

  public render(): JSX.Element {
    return (
      <article>
        <article className="not-mobile-article">
          <article className={css("mx-0 my-auto mw-100 d-flex justify-content-center")}>
            <button
              className={css("btn btn-lg btn-primary")}
              onClick={(e: any) => this.zoomOut()}
            >
              <em className="material-icons icon">zoom_out</em>
            </button>
            <button className={css("btn btn-light")}>
              {Math.round(this.state.scale * 100)}%
            </button>
            <button
              className={css("btn btn-lg btn-primary")}
              onClick={(e: any) => this.zoomIn()}
            >
              <em className="material-icons icon">zoom_in</em>
            </button>
          </article>
        </article>
        <article className="mobile-article">
          <article className={css("mx-0 my-auto mw-100 d-flex justify-content-center")}>
            <button
              className={css("btn btn-md btn-primary")}
              onClick={(e: any) => this.zoomOut()}
            >
              <em className="material-icons icon">zoom_out</em>
            </button>
            <button className={css("btn btn-light")}>
              {Math.round(this.state.scale * 100)}%
            </button>
            <button
              className={css("btn btn-md btn-primary")}
              onClick={(e: any) => this.zoomIn()}
            >
              <em className="material-icons icon">zoom_in</em>
            </button>
          </article>
        </article>
      </article>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    scale: state.documentState.scale,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  changeScale: (scale: number) => dispatch(changeScale(scale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomComponent as any);
