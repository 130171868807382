import { AjaxRequest } from "rxjs/Rx";
import { Observable } from "rxjs";
import { AjaxResponse } from "rxjs/observable/dom/AjaxObservable";
import { WidgetConfiguration } from "@sgwt-widget/core";
import { of } from "rxjs/observable/of";

export function ajaxCall(urlOrRequest: AjaxRequest | string, widgetConfiguration: WidgetConfiguration): Observable<AjaxResponse> {
  const currentUrl: string = window.location.href;
  if (currentUrl.includes('/p/')) {
    const urlParam = currentUrl.split('/p/');
    const token = urlParam.length > 0 ? urlParam[1].split('/')[0] : null;
    if (typeof urlOrRequest == "string") {
      return Observable.ajax({
        url: urlOrRequest.replace("/rest", "/rest/public"),
        headers: { 'token': token }
      });
    } else {
      urlOrRequest.headers = { ...urlOrRequest.headers, Token: token } as object;


      if (urlOrRequest.url) {
        if (!urlOrRequest.url.includes("/v2/transactions")) {
          urlOrRequest.url = urlOrRequest.url.replace("/rest", "/rest/public").replace("/v2", "/rest/public").replace("transactions", "transaction");
        } else {
          urlOrRequest.url = urlOrRequest.url.replace("/v2", "/v2/public");
        }
      }

      return Observable.ajax(urlOrRequest);
    }
  } else {
    let bearerToken = '';
    const getBearerFromWidgetConfigurationBus = widgetConfiguration.bus.subscribe<string>(
      'sg-connect.access-token',
      async (bearer: string | undefined) => {
        if (bearer) {
          bearerToken = bearer;
        }
      }
    );
    if (bearerToken) {
      widgetConfiguration.bus.unsubscribe(getBearerFromWidgetConfigurationBus);
    }
    if (typeof urlOrRequest == "string") {
      return Observable.ajax({
        url: urlOrRequest,
        headers: {
          'Authorization': bearerToken
        }
      });
    } else {
      urlOrRequest.headers = { ...urlOrRequest.headers, 'Authorization': bearerToken } as object;
      return Observable.ajax(urlOrRequest);
    }
  }
}
