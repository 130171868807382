import {
  IWidgetConfigurationProps,
  withWidgetConfiguration,
} from "@sgwt-widget/core";
import { Component, h } from "preact";
import { MarkupText, Text } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { IChannel } from "../../../common/domain/IChannel";
import { IOtp, OtpMode } from "../../../common/domain/IOtp";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import { ITransaction } from "../../../common/domain/ITransaction";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { IUser } from "../../../common/domain/User";
import { initProofOfConsent } from "../../../common/services/ProofOfConsentService";
import { IProofOfConsent } from "../../../common/domain/IProofOfConsent";
import { getQualifiedSignatureUrl, getSignatureId, getCurrentUserToken, getSignatureIframe } from "../../../common/services/OrelyUrlService";
import { hasSigned } from "../../../common/services/SignatureService";
import { cancelSignature, transactionSigned } from "../../actions/ISignatureAction";
import { transactionUpdated } from "../../actions";
import { documentLoaded } from "../../actions/IDocumentAction";

interface IQualifiedOtpModalState {
  channel: IChannel;
  open: boolean;
  otp: IOtp;
  processing: boolean;
  transaction: ITransaction;
  documentsCount: number;
  proofOfConsent: IProofOfConsent;
  orelyUrl: string;
  iFrame: any;
}

export interface IQualifiedOtpModalProps {
  transactionId: number;
  signatureProcessing: boolean;
  language: string;
  otpMode: OtpMode;
  phoneNumberIsValid: boolean;
  documentLoaded: (loaded: boolean) => void;
  setOtp: (otp: IOtp) => void;
  transactionSigned: (isSigned: boolean, transaction: ITransaction) => void;
  checkPhoneNumber: () => void;
  pocId: number;
  isPocSigned: boolean;
  forcePocDisplay: boolean;
  loadDocuments: (documents: ITransactionDocument[]) => void;
  currentUserIsSignatory: (valid: boolean) => void;
  user: IUser;
  pocSigned: (sign: boolean, pocId: number) => void;
  allDocumentsRead: (areRead: boolean) => void;
  pocIsCreated: (id: number) => void;
  cancelSignature: () => void;
  transaction: ITransaction;
  documents: ITransactionDocument[];
  currentLuxtrustDocumentId: string;
}

const QualifiedOtpModal = withWidgetConfiguration(
  class extends Component<IQualifiedOtpModalProps & IWidgetConfigurationProps,
    IQualifiedOtpModalState> {

    public async componentDidMount() {
      this.handleSignatureState(this.props);
      window.addEventListener('message', (e) => {
        this.signEventListener(e);
      }
      );
    }

    public async componentWillReceiveProps(nextProps: IQualifiedOtpModalProps) {
      this.handleSignatureState(nextProps);
    }

    public async handleSignatureState(props: IQualifiedOtpModalProps) {
      if (!this.state.open && props.signatureProcessing) {
        if (OtpMode.MOBILE === props.otpMode) {
          this.setState({ open: true, processing: true });
          if (!this.props.isPocSigned) {
            this.setState({
              proofOfConsent: await initProofOfConsent(this.props.widgetConfiguration, this.props.language.toUpperCase(), this.props.transactionId)
            });
            this.props.pocIsCreated(this.state.proofOfConsent.id);
          }
          this.setState({ open: props.signatureProcessing });
          if (this.state.iFrame === undefined) {
            this.getOrelyUrl();
          }
        }
      }
    }

    public getOrelyUrl() {
      let userAccessId = '0';
      this.props.transaction.signatureCounterparts.forEach(c => {
        const signatory = c.counterpartSignatories.filter(s =>
          s.identifier === this.props.user.username);
        if (signatory.length > 0) {
          userAccessId = signatory[0].morphoAccessId;
          userAccessId = userAccessId.indexOf("#") > -1 ? userAccessId.split("#")[0] : userAccessId;
        }
      });

      Promise.all([
        getQualifiedSignatureUrl(this.props.widgetConfiguration),
        getCurrentUserToken(this.props.widgetConfiguration),
        getSignatureId(this.props.widgetConfiguration, this.props.transaction.id)
      ]).then(values => {
        const baseUrl = values[0];
        const token = values[1];
        const signatureId = values[2];

        const url = `${baseUrl}/sign/${signatureId}/${userAccessId}`;
        getSignatureIframe(this.props.widgetConfiguration, url, token).then(srcDoc => {
          this.setState({ iFrame: srcDoc });
        });
      });
    }

    public signEventListener(event: any) {
      const transactionId: number = this.props.transactionId;
      const data = event.data;
      if (data && data.iFrameKey) {
        if (data.status === 'SUCCEEDED') {
          hasSigned(
            this.props.widgetConfiguration,
            transactionId,
            this.props.language
          )
            .then((transaction: ITransaction) => {
              this.setState({
                open: false,
                processing: false,
                transaction
              });
            })
            .then(() => {
              this.props.documentLoaded(false);
              return this.props.transactionSigned(true, this.state.transaction);
            })
            .catch(() =>
              this.setState({
                processing: false
              })
            );
        } else if (data.status === 'FAILED') {
          this.props.cancelSignature();
          this.setState({
            open: false,
            processing: false,
            iFrame: undefined
          });
        }

      }
    }

    public render(): JSX.Element {
      if (this.state.open && this.state.processing) {
        return (
          <article>
            <div id={'modal'}
              className={`${css("modal fade show")} opened`}
              tabIndex={-1}
              role="dialog"
            >
              <div
                className={css("modal-dialog modal-dialog-centered")}
                role="document"
              >
                <div className={css("modal-content shadow-max")}>
                  <div className={css("modal-header")}>
                    <h3 className={css("modal-title")}>
                      <MarkupText id={this.props.language + ".qualified-otp-modal.title"}>
                        One time password
                      </MarkupText>
                    </h3>
                  </div>
                  <div className={css("modal-body")}>
                    <iframe id="signaturePdfIframe" name="orely" srcDoc={this.state.iFrame}
                      width="100%" height="700px"
                      scrolling="yes" style="border: 0"></iframe>
                  </div>
                  <div className={css("modal-footer")}>
                  </div>
                </div>
              </div>
            </div>
          </article>
        );
      }
      return <article />;
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    consent: state.signatureState.consent,
    language: state.languageState.language,
    otpMode: state.signatureState.otpMode,
    phoneNumberIsValid: state.signatureState.phoneNumberIsValid,
    signatureProcessing: state.signatureState.processing,
    transactionId: state.transactionIdState.transactionId,
    pocId: state.pocState.pocId,
    isPocSigned: state.pocState.isPocSigned,
    forcePocDisplay: state.pocState.forceDisplay,
    user: state.userState.user,
    allDocumentsRead: state.signatureState.allDocumentsRead,
    transaction: state.transactionState.transaction,
    documents: state.documentState.documents,
    currentDocumentId: state.documentState.currentDocumentId,
    currentLuxtrustDocumentId: state.documentState.documents.filter(d => d.id === state.documentState.currentDocumentId)[0] ?
      state.documentState.documents.filter(d => d.id === state.documentState.currentDocumentId)[0].morphoDocumentId : 0,
    processing: state.signatureState.processing,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  cancelSignature: () => dispatch(cancelSignature()),
  transactionSigned: (isSigned: boolean, transaction: ITransaction) => {
    dispatch(transactionSigned(isSigned));
    dispatch(transactionUpdated(transaction));
  },
  documentLoaded: (loaded: boolean) => dispatch(documentLoaded(loaded)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QualifiedOtpModal as any);
