import { OtpMode } from "../../common/domain/IOtp";
import {
  CONFIRM_SIGNATURE_REJECTION_TYPE,
  IRejectionAction,
} from "../actions/IRejectionAction";
import {
  ACCEPT_CONSENT_TYPE,
  ALL_DOCUMENTS_READ_TYPE, CANCEL_SIGNATURE_TYPE,
  CHECK_PHONE_NUMBER_TYPE, ERROR_CONSENT_NOT_CHECK_TYPE,
  INIT_WORKFLOW,
  IS_OTP_VALID,
  ISignatureAction,
  PHONE_NUMBER_CONFIRMATION_TYPE,
  REQUEST_SIGNATURE_TYPE, REQUEST_SIGNATURE_WITHOUT_OTP_TYPE,
  SET_CHANNEL_TYPE,
  SET_CONSENT,
  IS_PUBLIC_SIGNATORY,
  SET_SIGNATURE_WORKFLOW,
  SUBMIT_OTP_TYPE,
  TRANSACTION_SIGNED_TYPE,
  UPDATE_DOCUMENTS_VALIDATION,
  UPDATE_SIGNATURE_STEP,
  SET_OTP_MODE,
  SET_CERTIFICATE_PENDING,
} from "../actions/ISignatureAction";
import { ISignatureState } from "../states/ISignatureState";
import { TRANSACTION_LOADED_TYPE } from "../actions";
import { POC_IS_SIGNED_TYPE } from "../actions/IPocAction";

function buildAcceptConsentState(state: ISignatureState,
  action: ISignatureAction): ISignatureState {
  return action.consent
    ? Object.assign({}, state, { consent: action.consent })
    : Object.assign({}, state, { consent: undefined });
}

function buildRequestSignatureState(state: ISignatureState,
  action: ISignatureAction): ISignatureState {
  if (state.signable && !state.processing && state.allDocumentsRead) {
    if (state.consent) {
      return Object.assign({}, state, {
        otpMode: action.otpMode,
        phoneNumberIsValid: action.phoneNumberIsValid,
        processing: true,
        errorConsent: false,
      });
    } else {
      return Object.assign({}, state, { errorConsent: true });
    }
  }
  return state;
}

function buildPhoneConfirmationState(state: ISignatureState,
  action: ISignatureAction) {
  return Object.assign({}, state, {
    checkPhoneNumber: action.checkPhoneNumber,
    otpMode: !action.phoneNumberIsValid ? OtpMode.EMAIL : state.otpMode,
    phoneNumberIsValid: action.phoneNumberIsValid,
  });
}

function setConsentsState(state: ISignatureState, step: number | undefined, consent: string | undefined): ISignatureState {
  let consents;
  if (!state.consents) {
    consents = new Map<number, string[]>();
  } else {
    consents = new Map(state.consents);
  }

  if (step !== undefined) {
    const exisitingConsents = consents.get(step);
    if (consent) {
      if (exisitingConsents) {
        if (!exisitingConsents.find(exisitingConsent => exisitingConsent === consent)) {
          exisitingConsents.push(consent)
          consents.set(step, exisitingConsents);
        } else {
          const updatedConsents = exisitingConsents.filter(exisitingConsent => exisitingConsent !== consent);
          consents.set(step, updatedConsents);
        }
      } else {
        consents.set(step, [consent]);
      }
    } else {
      consents.set(step, undefined);
    }
  }
  return {
    ...state,
    consents
  };
}

const initWorkflow = (state: ISignatureState): ISignatureState => {
  return {
    ...state,
    signatureWorkflow: undefined,
    documentsValidation: false,
    consents: new Map<number, string[]>()
  }
}

function buildParticularState(state: ISignatureState, action: ISignatureAction): ISignatureState | undefined {
  switch (action.type) {
    case POC_IS_SIGNED_TYPE:
      return Object.assign({}, state, { consent: null, allDocumentsRead: false, signed: false });
    case REQUEST_SIGNATURE_WITHOUT_OTP_TYPE:
      return Object.assign({}, state, { otpMandatory: false, signing: action.signing });
    case CONFIRM_SIGNATURE_REJECTION_TYPE:
      return Object.assign({}, state, { signable: false });
    case CANCEL_SIGNATURE_TYPE:
      return Object.assign({}, state, { signing: false, processing: false });
    case UPDATE_SIGNATURE_STEP:
      return Object.assign({}, state, { step: action.step });
    case SET_SIGNATURE_WORKFLOW:
      return Object.assign({}, state, { signatureWorkflow: action.signatureWorkflow });
    case UPDATE_DOCUMENTS_VALIDATION:
      return Object.assign({}, state, { documenstValidation: action.documentsValidation });
    case IS_OTP_VALID:
      return Object.assign({}, state, { isOTPValid: action.isOTPValid });
    case INIT_WORKFLOW:
      return initWorkflow(state);
    case SET_CONSENT:
      return setConsentsState(state, action.step, action.consent);
    case IS_PUBLIC_SIGNATORY:
      return Object.assign({}, state, { isPublicSignatory: action.isPublicSignatory });
    case SET_OTP_MODE:
      return Object.assign({}, state, { otpMode: action.otpMode });
    case SET_CERTIFICATE_PENDING:
      return Object.assign({}, state, { isCertificatePending: action.isCertificatePending });
  }
  return undefined;
}

function buildSignatureProcessState(state: ISignatureState, action: ISignatureAction): ISignatureState | undefined {
  switch (action.type) {
    case REQUEST_SIGNATURE_TYPE:
      return buildRequestSignatureState(state, action);
    case SET_CHANNEL_TYPE:
      return Object.assign({}, state, { channel: action.channel });
    case SUBMIT_OTP_TYPE:
      return Object.assign({}, state, { otp: action.otp });
    case TRANSACTION_SIGNED_TYPE:
      return Object.assign({}, state, {
        processing: false,
        signed: action.signed,
      });
    case TRANSACTION_LOADED_TYPE:
      return Object.assign({}, state, {
        processing: false,
        signed: false,
      });
    case ERROR_CONSENT_NOT_CHECK_TYPE:
      return Object.assign({}, state, { errorConsent: action.errorConsent });
    case UPDATE_SIGNATURE_STEP:
      return Object.assign({}, state, { step: action.step });
    case SET_SIGNATURE_WORKFLOW:
      return Object.assign({}, state, { signatureWorkflow: action.signatureWorkflow });
    case UPDATE_DOCUMENTS_VALIDATION:
      return Object.assign({}, state, { documentsValidation: action.documentsValidation });
    case IS_OTP_VALID:
      return Object.assign({}, state, { isOTPValid: action.isOTPValid });
    case INIT_WORKFLOW:
      return initWorkflow(state);
    case SET_CONSENT:
      return setConsentsState(state, action.step, action.consent);
    case IS_PUBLIC_SIGNATORY:
      return Object.assign({}, state, { isPublicSignatory: action.isPublicSignatory });
    case SET_OTP_MODE:
      return Object.assign({}, state, { otpMode: action.otpMode });
  }
  return undefined;
}

function buildVerificationProcessState(state: ISignatureState, action: ISignatureAction): ISignatureState | undefined {
  switch (action.type) {
    case ALL_DOCUMENTS_READ_TYPE:
      return Object.assign({}, state, {
        allDocumentsRead: action.allDocumentsRead,
      });
    case ACCEPT_CONSENT_TYPE:
      return buildAcceptConsentState(state, action);
    case CHECK_PHONE_NUMBER_TYPE:
      return Object.assign({}, state, {
        checkPhoneNumber: action.checkPhoneNumber,
      });
    case PHONE_NUMBER_CONFIRMATION_TYPE:
      return buildPhoneConfirmationState(state, action);
    case UPDATE_SIGNATURE_STEP:
      return Object.assign({}, state, { step: action.step });
    case SET_SIGNATURE_WORKFLOW:
      return Object.assign({}, state, { signatureWorkflow: action.signatureWorkflow });
    case UPDATE_DOCUMENTS_VALIDATION:
      return Object.assign({}, state, { documentsValidation: action.documentsValidation });
    case IS_OTP_VALID:
      return Object.assign({}, state, { isOTPValid: action.isOTPValid });
    case INIT_WORKFLOW:
      return initWorkflow(state);
    case SET_CONSENT:
      return setConsentsState(state, action.step, action.consent);
    case IS_PUBLIC_SIGNATORY:
      return Object.assign({}, state, { isPublicSignatory: action.isPublicSignatory });
    case SET_OTP_MODE:
      return Object.assign({}, state, { otpMode: action.otpMode });
  }
  return undefined;
}

export function signatureReducer(state: ISignatureState = {
  allDocumentsRead: false,
  processing: false,
  signable: true,
  signed: false,
  otpMandatory: true,
  errorConsent: false,
  documentsValidation: false,
  step: 0,
  isOTPValid: false,
  isCertificatePending: false
},
  action: ISignatureAction & IRejectionAction): ISignatureState {
  const isVerificationProcessState = buildVerificationProcessState(state, action);
  if (isVerificationProcessState) {
    return isVerificationProcessState;
  }

  const isSignatureProcessState = buildSignatureProcessState(state, action);
  if (isSignatureProcessState) {
    return isSignatureProcessState;
  }

  const isParticularProcessState = buildParticularState(state, action);
  if (isParticularProcessState) {
    return isParticularProcessState;
  }

  return state;
}

