import {IEventState} from "../states/IEventState";
import {
  IEventAction,
  RESET_ON_ERROR_EVENT_TYPE,
  RESET_ON_LOAD_EVENT_TYPE,
  RESET_ON_LOADED_EVENT_TYPE,
  RESET_ON_UPDATE_EVENT_TYPE
} from "../actions/IEventAction";
import {
  ITransactionLoadedAction,
  SELECT_TRANSACTION_TYPE,
  TRANSACTION_LOADED_TYPE,
  TRANSACTION_UPDATED_TYPE
} from "../actions";

export function eventReducer(state: IEventState = {},
                             action: IEventAction): IEventState {
  switch (action.type) {
    case SELECT_TRANSACTION_TYPE:
      return Object.assign({}, state, {onLoad: {data: action.data}});
    case TRANSACTION_LOADED_TYPE:
      return Object.assign({}, state, {onLoaded: {data: action.data}});
    case TRANSACTION_UPDATED_TYPE:
      return Object.assign({}, state, {onUpdate: {data: action.data || (action as ITransactionLoadedAction).transaction}});
    case RESET_ON_LOAD_EVENT_TYPE:
      return Object.assign({}, state, {onLoad: undefined});
    case RESET_ON_LOADED_EVENT_TYPE:
      return Object.assign({}, state, {onLoaded: undefined});
    case RESET_ON_ERROR_EVENT_TYPE:
      return Object.assign({}, state, {onError: undefined});
    case RESET_ON_UPDATE_EVENT_TYPE:
      return Object.assign({}, state, {onUpdate: undefined});
  }
  return state;
}
