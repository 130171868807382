export function fromStyleLocals(locals: any) {
  return (...names: string[]) => {
    return names
      .map((n: string) => n.split(" "))
      .reduce(
        (previous: string[], current: string[]) => previous.concat(current),
        []
      )
      .map((n: string) => (locals[n] || "").trim())
      .join(" ");
  }
}
