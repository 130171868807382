import {Action} from "redux";

export const INTERMEDIATE_VALIDATOR_START_REJECTION_TYPE = 'INTERMEDIATE_VALIDATOR_START_REJECTION_TYPE';
export const INTERMEDIATE_VALIDATOR_CANCEL_REJECTION_TYPE = 'INTERMEDIATE_VALIDATOR_CANCEL_REJECTION_TYPE';
export const INTERMEDIATE_VALIDATOR_REJECTED_TYPE = 'INTERMEDIATE_VALIDATOR_REJECTED_TYPE';
export const INTERMEDIATE_VALIDATOR_FORWARDING_TYPE = 'INTERMEDIATE_VALIDATOR_FORWARDING_TYPE';
export const INTERMEDIATE_VALIDATOR_CANCEL_FORWARDING_TYPE = 'INTERMEDIATE_VALIDATOR_CANCEL_FORWARDING_TYPE';

export interface IIntermediateValidatorAction extends Action<string> {
  rejected: boolean;
  rejecting: boolean;
  forwarding: boolean;
  forwarded: boolean;
}

export function forwardToSignatories(): IIntermediateValidatorAction {
  return {
    rejected: false,
    rejecting: false,
    forwarding: true,
    forwarded: false,
    type: INTERMEDIATE_VALIDATOR_FORWARDING_TYPE
  }
}

export function confirmForwarding() {
  return {
    rejected: false,
    rejecting: false,
    forwarding: false,
    forwarded: true,
    type: INTERMEDIATE_VALIDATOR_CANCEL_FORWARDING_TYPE
  }
}

export function cancelForwarding() {
  return {
    rejected: false,
    rejecting: false,
    forwarding: false,
    forwarded: false,
    type: INTERMEDIATE_VALIDATOR_CANCEL_FORWARDING_TYPE
  }
}

export function confirmContractRejection(): IIntermediateValidatorAction {
  return {
    rejected: true,
    rejecting: false,
    forwarding: false,
    forwarded: false,
    type: INTERMEDIATE_VALIDATOR_REJECTED_TYPE
  };
}

export function cancelContractRejection(): IIntermediateValidatorAction {
  return {
    rejected: false,
    rejecting: false,
    forwarding: false,
    forwarded: false,
    type: INTERMEDIATE_VALIDATOR_CANCEL_REJECTION_TYPE
  };
}

export function rejectContract(): IIntermediateValidatorAction {
  return {
    rejected: false,
    rejecting: true,
    forwarding: false,
    forwarded: false,
    type: INTERMEDIATE_VALIDATOR_START_REJECTION_TYPE
  };
}
