import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { SpinnerWidget } from "../../components/spinner/SpinnerWidget";
import { css } from "../../styles";

interface IWorkflowButtonProps {
  isLastStep: boolean,
  isDisabled: boolean,
  isSpinning: boolean,
  language: string,
  label: string,
  class: string,
  icon: string,
  iconOrder: number,
  onClick: () => void,
}

export class WorkflowButton extends Component<IWorkflowButtonProps, {}> {
  private readonly SPINNER_SIZE = 15;
  private readonly PREVIOUS_ICON_ORDER = 0;
  private readonly NEXT_ICON_ORDER = 1;

  private renderIcon(): JSX.Element {
    return <em class="material-icons md-18 vertical-align-middle">{this.props.icon}</em>
  }

  public render(): JSX.Element {
    return (
      <button className={css(`${this.props.class}`)} onClick={() => this.props.onClick()} disabled={this.props.isDisabled}>

        { this.props.iconOrder === this.PREVIOUS_ICON_ORDER && this.renderIcon() }

        { this.props.isSpinning && this.props.isLastStep && <SpinnerWidget size={this.SPINNER_SIZE}></SpinnerWidget> }

        &nbsp;
        <MarkupText id={this.props.language + this.props.label} class="vertical-align-middle"></MarkupText>
        &nbsp;

        { this.props.iconOrder === this.NEXT_ICON_ORDER && this.renderIcon() }

      </button>
    )
  }
}