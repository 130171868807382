import { Component, h } from "preact";
import { connect } from "preact-redux";
import { Action } from "redux";
import { printCurrentDocument } from "../../actions/IDocumentAction";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import { Origin } from "../../../common/domain/Origin";
import { Provider } from "../../../common/domain/Provider";
import { isMigratedDocusignDocument } from "../../../common/helpers/documentHelper";

interface IPrintButtonState {
  isPrinting: boolean;
}

export interface IPrintButtonProps {
  currentDocumentUrl: string;
  isPrinting: boolean;
  printCurrentDocument: (processing: boolean) => void;
  isMigratedDocusignDocument: boolean;
}

class PrintButton extends Component<IPrintButtonProps, IPrintButtonState> {
  public async componentDidMount() {
    this.setState({
      isPrinting: false,
    });
  }

  public async componentWillReceiveProps(nextProps: IPrintButtonProps) {
    this.setState({
      isPrinting: nextProps.isPrinting,
    });
  }

  public print(): void {
    this.setState({
      isPrinting: true,
    });
    this.props.printCurrentDocument(true);
  }

  public render(): JSX.Element {
    return (
      <article>
        <button
          className={`${css("btn btn-lg btn-flat-primary")} not-mobile-article`}
          disabled={this.props.isMigratedDocusignDocument}
          onClick={(e: any) => this.print()}
        >
          <em className={`material-icons`}>print</em>
        </button>
        <button
          className={`${css("btn btn-md btn-flat-primary")} mobile-article`}
          disabled={this.props.isMigratedDocusignDocument}
          onClick={(e: any) => this.print()}
        >
          <em className={`material-icons`}>print</em>
        </button>
      </article>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    isMigratedDocusignDocument: isMigratedDocusignDocument(state),
    currentDocumentUrl: state.documentState.currentDocumentUrl,
    isPrinting: state.documentState.isPrinting,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  printCurrentDocument: (processing: boolean) =>
    dispatch(printCurrentDocument(processing)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintButton as any);
