import { IWidgetConfigurationProps, withWidgetConfiguration, } from '@sgwt-widget/core';
import { Component, h } from 'preact';
import { MarkupText } from 'preact-i18n';
import { connect } from 'preact-redux';
import { Action } from 'redux';
import { ICounterpartSignatory } from '../../../common/domain/ICounterpartSignatory';
import { ISignatureCounterpart } from '../../../common/domain/ISignatureCounterpart';
import { ITransaction } from '../../../common/domain/ITransaction';
import { forwardTransactionToSignatories } from '../../../common/services/IntermediateValidatorService';
import { currentUserIsIntermediateValidator, transactionUpdated } from '../../actions';
import { cancelForwarding, confirmForwarding } from '../../actions/IIntermediateValidatorAction';
import { ApplicationState } from '../../states/ApplicationState';
import { css } from '../../styles';
import SignatoryFormCard from '../signatory-form-card/SignatoryFormCard';
import { ITransactionValidator } from "../../../common/domain/ITransactionValidator";
import { IUser } from "../../../common/domain/User";

interface IState {
  signatoriesByCounterparts: ICounterpartSignatory[][];
  counterpartsClientNames: string[];
  error: ErrorTypes;
  errorMessage: string;
}

const EXTERNAL = 'EXTERNAL';
const LAWYER = 'LAWYER';

enum ErrorTypes {
  HTTP, INVALID, NO_ERROR
}

export interface IForwardToSignatoriesPageProps {
  language: string;
  cancelForwarding: () => void;
  forwardIsSuccessful: (transaction: ITransaction) => void;
  externalCounterparts: ISignatureCounterpart[];
  transactionId: number;
  currentUser: IUser;
}

const ForwardToSignatoriesPage = withWidgetConfiguration(
  class extends Component<IForwardToSignatoriesPageProps & IWidgetConfigurationProps, IState> {

    public componentWillMount() {
      const signatoriesByCounterparts: ICounterpartSignatory[][] = [];
      this.props.externalCounterparts.forEach(() => signatoriesByCounterparts.push([{} as any]));
      this.setState({ signatoriesByCounterparts, error: ErrorTypes.NO_ERROR });
    }

    public render(): JSX.Element {
      const signatoriesTable = this.getCounterpartsForCurrentIntermediateValidator().map((counterpart, index) => (
        <article>
          <h4>{counterpart.name}&nbsp;
            <MarkupText id={this.props.language + '.forward-to-signatories-page.signatory-title'}>
              Signatory
            </MarkupText>
          </h4>
          <section className={css('d-flex flex-wrap mb-2')}>
            {this.renderSignatoriesCards(this.state.signatoriesByCounterparts[index], index)}
            <button className={css('btn align-self-center btn-link ml-4')} type="button"
              onClick={this.addNewSignatoryToCounterpart.bind(this, index)}>
              <em className={`${css('icon-xl')} material-icons icon`}>add_circle</em>
            </button>
          </section>
        </article>
      ));
      return (
        <form>
          {signatoriesTable}
          <section className={css('d-flex align-items-center flex-row-reverse justify-content-end')}>
            {this.state.error === ErrorTypes.INVALID
              && (<span className={css('ml-2 invalid-feedback d-inline')} style="font-size: 1rem">
                <MarkupText id={this.props.language + '.forward-to-signatories-page.error.invalid'}>All fields are mandatory</MarkupText>
              </span>)}
            {this.state.error === ErrorTypes.HTTP
              && (<span className={css('ml-2 invalid-feedback d-inline')} style="font-size: 1rem">
                <MarkupText
                  id={this.props.language + ('.forward-to-signatories-page.error.' + (this.state.errorMessage ? this.state.errorMessage : 'http'))}>An error occurred</MarkupText>
              </span>)}
            <button className={css('btn btn-primary')} onClick={this.submitSignatories.bind(this)}>
              <MarkupText id={this.props.language + '.forward-to-signatories-page.save signatories'}>Save
                signatories</MarkupText>
            </button>
            <button className={css('btn sgbs-btn-default mr-2')} onClick={this.props.cancelForwarding.bind(this)}>
              <MarkupText id={this.props.language + '.forward-to-signatories-page.cancel'}>Cancel</MarkupText>
            </button>
          </section>
        </form>);
    }

    private getCounterpartsForCurrentIntermediateValidator(): ISignatureCounterpart[] {
      return this.props.externalCounterparts.filter((counterpart: ISignatureCounterpart) =>
        counterpart.counterpartValidators.find((validator: ITransactionValidator) =>
          this.props.currentUser && validator.email.toLowerCase() === this.props.currentUser.username.toLowerCase()));
    }

    private renderSignatoriesCards(signatories: ICounterpartSignatory[], counterpartIndex: number) {
      return signatories.map((signatory, index) =>
      (<SignatoryFormCard signatory={signatory} language={this.props.language}
        handleUpdateSignatory={this.updateSignatoryField.bind(this, counterpartIndex, index)}
        handleDeletion={this.removeSignatory.bind(this, counterpartIndex, index)}
      />)
      );
    }

    private removeSignatory(counterpartIndex: number, signatoryIndex: number) {
      const signatoriesByCounterparts = this.state.signatoriesByCounterparts;
      if (signatoriesByCounterparts[counterpartIndex].length > 1) {
        signatoriesByCounterparts[counterpartIndex].splice(signatoryIndex, 1);
      } else {
        signatoriesByCounterparts[counterpartIndex][signatoryIndex] = {} as any;
      }
      this.setState({ signatoriesByCounterparts });
    }

    private addNewSignatoryToCounterpart(counterpartIndex: number) {
      const signatoriesByCounterparts = this.state.signatoriesByCounterparts;
      signatoriesByCounterparts[counterpartIndex].push({} as any);
      this.setState({ signatoriesByCounterparts });
    }

    private updateSignatoryField(counterpartIndex: number, signatoryIndex: number, updatedField: { [index: string]: string }) {
      const signatoriesByCounterparts = this.state.signatoriesByCounterparts;
      signatoriesByCounterparts[counterpartIndex][signatoryIndex] = {
        ...signatoriesByCounterparts[counterpartIndex][signatoryIndex],
        ...updatedField
      };
      signatoriesByCounterparts[counterpartIndex][signatoryIndex].organization = this.state.counterpartsClientNames[counterpartIndex + 1];
    }

    private submitSignatories(event: Event) {
      if (this.areSignatoriesValid()) {
        this.setState({ error: ErrorTypes.NO_ERROR });
        const counterparts = this.getCounterpartsForCurrentIntermediateValidator();
        counterparts.map((c, index) => (c as any).counterpart = {
          signatories: this.state.signatoriesByCounterparts[index],
          order: c.order
        });
        forwardTransactionToSignatories(this.props.widgetConfiguration, this.props.transactionId, counterparts)
          .then((transaction) => this.props.forwardIsSuccessful(transaction))
          .catch((error) => {
            this.setState({
              error: ErrorTypes.HTTP, errorMessage: error.response
                ? error.response.message
                : 'An error occured'
            });
          });
      } else {
        this.setState({ error: ErrorTypes.INVALID });
      }
      event.preventDefault();
      return false;
    }

    private areSignatoriesValid(): boolean {
      return this.getCounterpartsForCurrentIntermediateValidator().every((counterpart: ISignatureCounterpart) =>
        counterpart.counterpartSignatories.every(signatory =>
        (!!signatory.identifier && (signatory.identifier.trim() !== '')
          && !!signatory.firstname && (signatory.firstname.trim() !== '')
          && !!signatory.lastname && (signatory.lastname.trim() !== '')
          && !!signatory.phoneNumber && (signatory.phoneNumber.trim() !== '')
        )
        )
      );
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    currentUser: state.userState.user,
    language: state.languageState.language,
    transactionId: state.transactionState.transaction!.id,
    externalCounterparts: (state.transactionState.transaction && state.transactionState.transaction.signatureCounterparts)
      ? state.transactionState.transaction.signatureCounterparts.filter(c => c.type === EXTERNAL || c.type === LAWYER) : [],
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  cancelForwarding: () => dispatch(cancelForwarding()),
  forwardIsSuccessful: (transaction: ITransaction) => {
    dispatch(confirmForwarding());
    dispatch(currentUserIsIntermediateValidator(false));
    dispatch(transactionUpdated(transaction));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForwardToSignatoriesPage as any);
