import { Component, h } from "preact";
import Checkbox from "../../checkbox/Checkbox";
import { PaginationComponent } from "./pagination-component/PaginationComponent";
import { css } from "../../../styles";
import { SignButtonsComponent } from "./sign-buttons-component/SignButtonsComponent";
import { OtpMode } from "../../../../common/domain/IOtp";
import { CancelSignatureComponent } from "./cancel-signature-component/CancelSignatureComponent";
import { ITransaction } from "../../../../common/domain/ITransaction";

interface ISignatureComponentProps {
  transaction: ITransaction,
  language: string,
  phoneNumber: string,
  signable: boolean,
  allDocumentsRead: boolean,
  isPublicSignatory: boolean,
  isDisplayed: boolean,
  documentsCount: number,
  reject: () => void,
  sign: (otpMode: OtpMode, phoneNumberIsValid?: boolean) => void,
}

export class SignatureComponent extends Component<ISignatureComponentProps, {}> {
  public render(): JSX.Element {
    if (this.props.isDisplayed) {
      return (
        <section>
          <section className={css("row mt-2")}>
            <section className={`${css("col-10 col-lg-auto mb-4")} consent-checkbox`}>
              <Checkbox />
            </section>
            <PaginationComponent documentsCount={this.props.documentsCount}/>
          </section>
          <section className={css("row")}>
            <section className={css("col")}>
              <SignButtonsComponent
                transaction={this.props.transaction}
                language={this.props.language}
                phoneNumber={this.props.phoneNumber}
                signable={this.props.signable}
                allDocumentsRead={this.props.allDocumentsRead}
                isPublicSignatory={this.props.isPublicSignatory}
                sign={(otpMethod: OtpMode, phoneNumberIsValid?: boolean) => this.props.sign(otpMethod, phoneNumberIsValid)}
              />
              <CancelSignatureComponent
                language={this.props.language}
                allDocumentsRead={this.props.allDocumentsRead}
                reject={this.props.reject}
              />
            </section>
          </section>
        </section>
      );
    }
    return <section></section>;
  }
}