import { IWidgetConfigurationProps, withWidgetConfiguration } from "@sgwt-widget/core";
import { Component, h } from "preact";
import { connect } from "preact-redux";
import { ApplicationState } from "../../states/ApplicationState";
import { ISignatureWorkflow } from "../../../common/domain/ISignatureWorkflow";
import { ITransaction } from "../../../common/domain/ITransaction";
import { getSignatureWorkflowByLanguage } from "../../../common/services/SignatureWorkflow";

export interface IWorkflowBulletsProps {
  language: string;
  transaction: ITransaction;
  step: number;
  signatureWorkflow: ISignatureWorkflow;
}

const WorkflowBullets = withWidgetConfiguration(
  class extends Component<IWorkflowBulletsProps & IWidgetConfigurationProps> {

    public render(): JSX.Element {
      const translatedJson = getSignatureWorkflowByLanguage(this.props.language, this.props.signatureWorkflow.languages);
      const currentStep = translatedJson[this.props.step];
      const bullets = currentStep.bullets || [];
      const li: JSX.Element[] = [];
      bullets.forEach(bullet => {
        li.push(<li>{bullet}</li>)
      })
      return (
        <section>
          <br></br>
          <ul>
            {li}
          </ul>
        </section>
      )
    }

  });

function mapStateToProps(state: ApplicationState) {
  return {
    language: state.languageState.language,
    transaction: state.transactionState.transaction,
    step: state.signatureState.step,
    signatureWorkflow: state.signatureState.signatureWorkflow,
  }
};

export default connect(
  mapStateToProps,
)(WorkflowBullets as any);