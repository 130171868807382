import { ITransactionDocument, readOptionTypes } from "../../common/domain/TransactionDocument";
import {
  DOCUMENT_DOWNLOAD_TYPE,
  DOCUMENT_LOADED_TYPE,
  IDocumentAction,
  LOAD_DOCUMENTS_TYPE,
  PRINT_CURRENT_DOCUMENT_TYPE,
  READ_DOCUMENT_COMPLETION_TYPE,
  READ_DOCUMENT_TYPE,
  TOGGLE_FULLSCREEN_TYPE,
} from "../actions/IDocumentAction";
import {
  DEFAULT_SCALE,
  FullscreenMode,
  IDocumentState,
} from "../states/IDocumentState";

function buildReadDocumentState(
  state: IDocumentState,
  action: IDocumentAction
) {
  return Object.assign({}, state, {
    currentDocumentId: action.documentId || state.currentDocumentId,
    currentDocumentUrl: action.documentUrl || state.currentDocumentUrl,
    scale: action.scale || DEFAULT_SCALE,
  });
}

function buildLoadDocumentState(
  state: IDocumentState,
  action: IDocumentAction
) {
  return Object.assign({}, state, {
    documents: action.documents || state.documents,
  });
}

function buildToggleFullscreenState(
  state: IDocumentState,
  action: IDocumentAction
) {
  if (action.fullscreenMode !== state.fullscreenMode) {
    return Object.assign({}, state, {
      fullscreenMode: action.fullscreenMode,
    });
  } else {
    return Object.assign({}, state, {
      fullscreenMode: state.fullscreenMode,
    });
  }
}

function buildPrintDocumentState(
  state: IDocumentState,
  action: IDocumentAction
) {
  return Object.assign({}, state, { isPrinting: action.isPrinting });
}

function buildReadCompletionState(
  state: IDocumentState,
  action: IDocumentAction
) {
  return Object.assign({}, state, {
    documents: state.documents.map((doc: ITransactionDocument) => {
      if (
        doc.id === action.documentId &&
        action.readCompletion &&
        (doc.readCompletion || 0) < action.readCompletion
      ) {
        doc.readCompletion = action.readCompletion;
      }
      return doc;
    }),
  });
}

function buildDocumentLoadedState(
  state: IDocumentState,
  action: IDocumentAction
) {
  return Object.assign({}, state, {
    documents: state.documents.map((doc: ITransactionDocument) => {
      if (doc.id === state.currentDocumentId && action.pagesCount) {
        doc.pagesCount = action.pagesCount;
      }
      return doc;
    }),
    loaded: action.loaded,
  });
}

function buildDocumentDownloadStatusState(state: IDocumentState, action: IDocumentAction): IDocumentState {
  return Object.assign({}, state, {
    downloadStatus: action.download
  });
}

export function documentReducer(
  state: IDocumentState = {
    currentDocumentId: undefined,
    currentDocumentUrl: undefined,
    currentDocumentReadOption: readOptionTypes.READ_ALL,
    documents: [],
    fullscreenMode: FullscreenMode.OFF,
    isPrinting: false,
    loaded: false,
    scale: DEFAULT_SCALE,
  },
  action: IDocumentAction
): IDocumentState {

  switch (action.type) {
    case READ_DOCUMENT_TYPE:
      return buildReadDocumentState(state, action);
    case LOAD_DOCUMENTS_TYPE:
      return buildLoadDocumentState(state, action);
    case TOGGLE_FULLSCREEN_TYPE:
      return buildToggleFullscreenState(state, action);
    case PRINT_CURRENT_DOCUMENT_TYPE:
      return buildPrintDocumentState(state, action);
    case READ_DOCUMENT_COMPLETION_TYPE:
      return buildReadCompletionState(state, action);
    case DOCUMENT_LOADED_TYPE:
      return buildDocumentLoadedState(state, action);
    case DOCUMENT_DOWNLOAD_TYPE:
      return buildDocumentDownloadStatusState(state, action);
  }
  return state;
}
