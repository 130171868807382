import { Action } from "redux";

export const REJECTING_SIGNATURE_TYPE = "REJECTING_SIGNATURE";
export const CANCEL_SIGNATURE_REJECTION_TYPE = "CANCEL_SIGNATURE_REJECTION";
export const CONFIRM_SIGNATURE_REJECTION_TYPE = "CONFIRM_SIGNATURE_REJECTION";

export interface IRejectionAction extends Action<string> {
  rejected?: boolean;
  rejecting?: boolean;
}

export function reject(): IRejectionAction {
  return {
    rejecting: true,
    type: REJECTING_SIGNATURE_TYPE,
  };
}

export function cancelRejection(): IRejectionAction {
  return {
    rejecting: false,
    type: CANCEL_SIGNATURE_REJECTION_TYPE,
  };
}

export function confirmRejection(): IRejectionAction {
  return {
    rejected: true,
    type: CONFIRM_SIGNATURE_REJECTION_TYPE,
  };
}
