import {WidgetConfiguration} from "@sgwt-widget/core";
import {IChannel} from "../domain/IChannel";
import {ajaxCall} from "./AjaxService";

export async function registerCertificate(widgetConfiguration: WidgetConfiguration,
                                          transactionId: number,
                                          channel: IChannel,
                                          consent: string): Promise<void> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    body: {
      channel,
      consent,
    },
    headers: {"Content-Type": "application/json"},
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/transactions/${transactionId}/certificate`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}
