import {WidgetConfiguration} from "@sgwt-widget/core";
import {IChannel} from "../domain/IChannel";
import {OtpMode} from "../domain/IOtp";
import {ajaxCall} from "./AjaxService";

const PHONE = "PHONE";
const EMAIL = "EMAIL";
const NONE = "NONE";

export async function openChannel(widgetConfiguration: WidgetConfiguration,
                                  transactionId: number,
                                  otpMode: OtpMode): Promise<IChannel> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }

  let otpType: string;
  if (otpMode === OtpMode.SMS) {
    otpType = PHONE;
  } else if (otpMode === OtpMode.EMAIL) {
    otpType = EMAIL;
  } else {
    otpType = NONE;
  }

  return ajaxCall({
    body: {otpType},
    headers: {"Content-Type": "application/json"},
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/transactions/${transactionId}/channel`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}
