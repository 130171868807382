import { IAbstractDocument } from "./AbstractDocument";

export enum readOptionTypes {
  READ_ALL = "READ_ALL",
  VIEW = "VIEW",
};

export interface ITransactionDocument extends IAbstractDocument {
  fileContent: string;
  filename: string;
  id: number;
  pagesCount: number;
  transactionId: number;
  mimeType: string;
  signaturePage: number;
  readCompletion?: number;
  readProgression?: number;
  readOption: readOptionTypes;
}
