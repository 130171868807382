import { ISignatureCounterpart } from "../domain/ISignatureCounterpart";
import { isExternalCounterpart } from "./counterpartHelper";

export function getTranslationId(
  language: string,
  currentCounterpart: ISignatureCounterpart | undefined
  ): string {
  return isExternalCounterpart(currentCounterpart)
    ? language + ".consent.localSigner"
    : language + ".consent.org"
}