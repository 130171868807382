import {Action} from "redux";
import {ITransactionDocument} from "../../common/domain/TransactionDocument";

export const RESET_ON_LOAD_EVENT_TYPE = "RESET_ON_LOAD_EVENT";
export const RESET_ON_LOADED_EVENT_TYPE = "RESET_ON_LOADED_EVENT";
export const RESET_ON_ERROR_EVENT_TYPE = "RESET_ON_ERROR_EVENT";
export const RESET_ON_UPDATE_EVENT_TYPE = "RESET_ON_UPDATE_EVENT";

export interface IEventAction extends Action<string> {
  data?: any;
}

export function resetOnLoad(): IEventAction {
  return {type: RESET_ON_LOAD_EVENT_TYPE};
}

export function resetOnLoaded(): IEventAction {
  return {type: RESET_ON_LOADED_EVENT_TYPE};
}

export function resetOnError(): IEventAction {
  return {type: RESET_ON_ERROR_EVENT_TYPE};
}

export function resetOnUpdate(): IEventAction {
  return {type: RESET_ON_UPDATE_EVENT_TYPE};
}
