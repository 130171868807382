import {Action} from "redux";
import {ITransactionAnnex} from "../../common/domain/ITransactionAnnex";
import {ITransactionDocument, readOptionTypes} from "../../common/domain/TransactionDocument";
import {DEFAULT_SCALE, FullscreenMode} from "../states/IDocumentState";
import {DocumentDownloadStatus} from "../../common/domain/DocumentDownloadStatus";

export const READ_DOCUMENT_TYPE = "READ_DOCUMENT";
export const DOCUMENT_LOADED_TYPE = "DOCUMENT_LOADED";
export const LOAD_DOCUMENTS_TYPE = "LOAD_DOCUMENTS";
export const TOGGLE_FULLSCREEN_TYPE = "TOGGLE_FULLSCREEN";
export const PRINT_CURRENT_DOCUMENT_TYPE = "PRINT_CURRENT_DOCUMENT";
export const READ_DOCUMENT_COMPLETION_TYPE = "READ_DOCUMENT_COMPLETION";
export const DOCUMENT_DOWNLOAD_TYPE = "DOCUMENT_DOWNLOAD";

export interface IDocumentAction extends Action<string> {
  documentUrl?: string;
  documents?: ITransactionDocument[];
  scale?: number;
  fullscreenMode?: FullscreenMode;
  isPrinting?: boolean;
  documentId?: number;
  readCompletion?: number;
  pagesCount?: number;
  loaded?: boolean;
  download?: DocumentDownloadStatus;
  readOption?: readOptionTypes;
}

export function loadDocuments(
  documents: ITransactionDocument[],
  documentAnnexes?: ITransactionAnnex[]
): IDocumentAction {
  documents.forEach(value => value.mustRead = true);

  if(documentAnnexes){
    documentAnnexes.forEach(value => value.mustRead = false);
  }

  Array.prototype.push.apply(documents, documentAnnexes);
  return {
    documents,
    type: LOAD_DOCUMENTS_TYPE,
  };
}

export function readDocument(
  documentId: number,
  documentUrl: string,
  scale?: number
): IDocumentAction {
  return {
    documentId,
    documentUrl,
    scale: scale || DEFAULT_SCALE,
    type: READ_DOCUMENT_TYPE,
  };
}

export function changeScale(scale: number): IDocumentAction {
  return {
    scale,
    type: READ_DOCUMENT_TYPE,
  };
}

export function enterFullscreenMode(): IDocumentAction {
  return {
    fullscreenMode: FullscreenMode.ON,
    type: TOGGLE_FULLSCREEN_TYPE,
  };
}

export function exitFullscreenMode(): IDocumentAction {
  return {
    fullscreenMode: FullscreenMode.OFF,
    type: TOGGLE_FULLSCREEN_TYPE,
  };
}

export function printCurrentDocument(processing: boolean): IDocumentAction {
  return {
    isPrinting: processing,
    type: PRINT_CURRENT_DOCUMENT_TYPE,
  };
}

export function readDocumentCompletion(
  documentId: number,
  readCompletion: number
): IDocumentAction {
  return {
    documentId,
    readCompletion,
    type: READ_DOCUMENT_COMPLETION_TYPE,
  };
}

export function dispatchDownloadStatus(downloadStatus: DocumentDownloadStatus): IDocumentAction {
  return {
    type: DOCUMENT_DOWNLOAD_TYPE,
    download: downloadStatus
  };
}

export function documentLoaded(
  loaded: boolean,
  pagesCount?: number
): IDocumentAction {
  return {
    loaded,
    pagesCount,
    type: DOCUMENT_LOADED_TYPE,
  };
}
