import {Action} from "redux";

export const SELECT_TRANSACTION_TYPE = "SELECT_TRANSACTION";

export interface ISelectTransactionAction extends Action<string> {
  transactionId: number;
}

export function selectTransaction(
  transactionId: number
): ISelectTransactionAction {
  return {
    transactionId,
    type: SELECT_TRANSACTION_TYPE,
  };
}
