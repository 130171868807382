import { Component, h } from "preact";
import { connect } from "preact-redux";
import { Action } from "redux";
import { ISignatureCounterpart } from "../../../common/domain/ISignatureCounterpart";
import { ICounterpartSignatory } from "../../../common/domain/ICounterpartSignatory";
import { ITransaction } from "../../../common/domain/ITransaction";
import { IUser } from "../../../common/domain/User";
import { findCurrentCounterpartAndSignatory } from "../../../common/services/UserService";
import { acceptConsent, errorConsent, setConsent } from "../../actions/ISignatureAction";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import { ISignatureWorkflow } from "../../../common/domain/ISignatureWorkflow";
import { getTranslationId } from "../../../common/helpers/translationHelper";
import { getCompanyName } from "../../../common/helpers/counterpartHelper";
import { CollateralCheckbox } from "./collateral-checkbox/CollateralCheckbox";
import { isConsentCheck } from "../../../common/helpers/transactionHelper";
import { ConsentCheckbox } from "./consent-checkbox/ConsentCheckbox";
import { DefaultCheckbox } from "../../components/default-checkbox/DefaultCheckbox";
import { getSignatureWorkflowByLanguage } from "../../../common/services/SignatureWorkflow";

interface ICheckboxState {
  language: string;
  currentCounterpart?: ISignatureCounterpart;
  currentSignatory?: ICounterpartSignatory;
  errorConsent: boolean;
  step: number;
  signatureWorkflow: ISignatureWorkflow;
}

export interface ICheckboxProps {
  consents: Map<number, string[]>;
  consent: string;
  language: string;
  user: IUser;
  transaction: ITransaction;
  acceptConsent: (consent: string | null) => void;
  isErrorConsent: boolean;
  errorConsent: (isInError: boolean) => void;
  step: number;
  signatureWorkflow: ISignatureWorkflow;
  setConsent: (step: number, consent: string | null) => void; 
}

class Checkbox extends Component<ICheckboxProps, ICheckboxState> {
  private consent: HTMLElement[] = [];
  private isConsentCheckboxesChecked: boolean[] = [];

  public async componentDidMount() {
    const current = findCurrentCounterpartAndSignatory(this.props.user, this.props.transaction);
    this.setState({
      currentCounterpart: current.counterpart,
      currentSignatory: current.signatory,
      language: this.props.language,
      step: this.props.step,
      signatureWorkflow: this.props.signatureWorkflow,
    });
  }

  public async componentWillReceiveProps(nextProps: ICheckboxProps) {
    this.setState({
      step: nextProps.step,
      signatureWorkflow: nextProps.signatureWorkflow,
    });
    if (nextProps.language) {
      this.setState({ language: nextProps.language });
    }
    if (nextProps.user && nextProps.transaction) {
      const current = findCurrentCounterpartAndSignatory(this.props.user, this.props.transaction);
      this.setState({
        currentCounterpart: current.counterpart,
        currentSignatory: current.signatory,
      });
    }
    if (nextProps.isErrorConsent) {
      this.setState({
        errorConsent: true
      });
    }
  }

  private addConsent(elt: HTMLElement) {
    if(elt && !this.consent.find(consent => consent.innerText === elt.innerText)){
      this.consent.push(elt);
      this.isConsentCheckboxesChecked.push(false)
    }
  }

  public acceptConsent(event: any) {
    this.props.errorConsent(false);
    if (this.props.signatureWorkflow) {
      const label = event.srcElement.labels[0].innerText;
      this.props.setConsent(this.props.step, label);
    } else {
      event.srcElement.checked
      ? this.props.acceptConsent(this.consent[0].innerText)
      : this.props.acceptConsent(null)
    }
  }

  private renderCheckboxes(): JSX.Element[] {
    const defaultLabel = "consent";
    const language = this.props.language;
    const isWorkflowPresent = this.props.signatureWorkflow != null;
    const companyName = getCompanyName(this.props.transaction, this.state.currentSignatory);
    const translationId = getTranslationId(this.state.language, this.state.currentCounterpart);
    if(isWorkflowPresent) {
      const translatedJson = getSignatureWorkflowByLanguage(this.props.language, this.props.signatureWorkflow.languages)
      return [
        <ConsentCheckbox
          step={this.state.step}
          consent={this.props.consent}
          language={language}
          companyName={companyName}
          translationId={translationId}
          isErrorConsent={this.props.isErrorConsent}
          transaction={this.props.transaction}
          existingConsents={this.props.consents}
          signatureWorkflowSteps={translatedJson}
          acceptConsent={(event: any) => this.acceptConsent(event)}
          addConsent={(elt: HTMLElement) => this.addConsent(elt)}
        />,
        <CollateralCheckbox
          step={this.state.step}
          consent={this.props.consent}
          language={language}
          companyName={companyName}
          translationId={translationId}
          isErrorConsent={this.props.isErrorConsent}
          transaction={this.props.transaction}
          existingConsents={this.props.consents}
          signatureWorkflowSteps={translatedJson}
          acceptConsent={(event: any) => this.acceptConsent(event)}
          addConsent={(elt: HTMLElement) => this.addConsent(elt)}
        />
      ];
    }
    return [
      <DefaultCheckbox
        label={defaultLabel}
        translationId={translationId}
        companyName={companyName}
        isErrorConsent={this.props.isErrorConsent}
        isCheckboxChecked={isConsentCheck(this.state.step, this.props.consent, defaultLabel, isWorkflowPresent, this.props.consents)}
        isBasedOnSignatureWorkflow={isWorkflowPresent}
        acceptConsent={(event: any) => this.acceptConsent(event)}
        addConsent={(elt: HTMLElement) => this.addConsent(elt)}
      />
    ];
  }

  public render(): JSX.Element {
    if (!this.state.currentSignatory) {
      return <div />;
    }
    return (
      <article>
        <div className={css("form-group form-check")}>
          {this.state.signatureWorkflow ?
            <br></br>
            : null
          }
          {this.renderCheckboxes()}
        </div>
      </article>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    consents: state.signatureState.consents,
    consent: state.signatureState.consent,
    language: state.languageState.language,
    transaction: state.transactionState.transaction,
    user: state.userState.user,
    isErrorConsent: state.signatureState.errorConsent,
    step: state.signatureState.step,
    signatureWorkflow: state.signatureState.signatureWorkflow,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  acceptConsent: (consent: string) => dispatch(acceptConsent(consent)),
  errorConsent: (isInError: boolean) => dispatch(errorConsent(isInError)),
  setConsent: (step: number, consent: string) => dispatch(setConsent(step, consent)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkbox as any);
