import { WidgetConfiguration } from "@sgwt-widget/core";

export async function getWorkerUrl(
  widgetConfiguration: WidgetConfiguration
): Promise<string> {
  const workerUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).pdfJsWorkerUrl;
  if (!workerUrl) {
    return Promise.reject("Missing PDf.js worker URL");
  }
  return workerUrl;
}
