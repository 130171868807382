import { widgetConfiguration, WidgetConfiguration } from "@sgwt-widget/core";
import { IProofOfConsent } from "../domain/IProofOfConsent";
import { ajaxCall } from "./AjaxService";
import { ITransaction } from "../domain/ITransaction";

export async function pocMustBeSigned(widgetConfiguration: WidgetConfiguration, transactionId: number): Promise<boolean> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/rest/proofOfConsent/must-be-signed/${transactionId}`,
  }, widgetConfiguration)
    .map((response) => response.response.value)
    .toPromise();
}

export async function getExistingPoc(widgetConfiguration: WidgetConfiguration, transactionId: number): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/rest/proofOfConsent/current/${transactionId}`,
  }, widgetConfiguration)
    .map((response) => response.response)
    .map((response: any) => {
      response.status = response.status || response.currentStatus;
      return response;
    })
    .toPromise();
}

export async function getLastPocVersion(widgetConfiguration: WidgetConfiguration,
  language: string,
  transactionId: number): Promise<IProofOfConsent> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/rest/proofOfConsent/${language}?transactionId=${transactionId}`,
  }, widgetConfiguration)
    .map((response) => response.response)
    .toPromise();
}

export async function initProofOfConsent(widgetConfiguration: WidgetConfiguration,
  language: string, transactionId: number): Promise<IProofOfConsent> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/proof-of-consents?language=${language}&transactionId=${transactionId}`,
  }, widgetConfiguration)
    .map((response) => response.response)
    .toPromise();
}
