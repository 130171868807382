import { ICounterpartSignatory } from "../domain/ICounterpartSignatory";
import { ISignatureCounterpart } from "../domain/ISignatureCounterpart";
import { ITransaction } from "../domain/ITransaction";
import { findCurrentSignatoryCounterpartName } from "../services/SignatureCounterpartService";

export function isExternalCounterpart(currentCounterpart: ISignatureCounterpart | undefined): boolean {
  return currentCounterpart !== undefined && ["LOCAL_SIGNER", "SIGNATORY"].includes(currentCounterpart.type);
}

export function getCompanyName(transaction: ITransaction, currentSignatory: ICounterpartSignatory | undefined): string {
  try {
    return findCurrentSignatoryCounterpartName(transaction, currentSignatory);
  } catch (e) {
    console.error(e);
    return "";
  }
}