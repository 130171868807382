import {WidgetConfiguration} from "@sgwt-widget/core";
import {IChannel} from "../domain/IChannel";
import {IOtp, OtpMode} from "../domain/IOtp";
import {ajaxCall} from "./AjaxService";

export async function requestOtp(
  widgetConfiguration: WidgetConfiguration,
  transactionId: number,
  channel: IChannel,
  otpMode: OtpMode,
  language: string
): Promise<IOtp> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }

  const otpType: string = otpMode === OtpMode.SMS ? "PHONE" : "EMAIL";
  return ajaxCall({
    body: {
      channel,
      otpType,
    },
    headers: { "Content-Type": "application/json" },
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/transactions/${transactionId}/otp?lang=${language}`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function submitOtp(
  widgetConfiguration: WidgetConfiguration,
  transactionId: number,
  otp: IOtp,
  channel: IChannel
): Promise<IOtp> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }

  return ajaxCall({
    body: {
      ...otp,
      channel
    },
    headers: { "Content-Type": "application/json" },
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/transactions/${transactionId}/otp/send`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}
