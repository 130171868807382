import { Component, h } from "preact";
import { ISignatureWorkflowStep } from "../../../../common/domain/ISignatureWorkflow";
import { ITransaction } from "../../../../common/domain/ITransaction";
import { isConsentCheck } from "../../../../common/helpers/transactionHelper";
import { DefaultCheckbox } from "../../../components/default-checkbox/DefaultCheckbox";

export interface IConsentCheckboxProps {
  step: number,
  consent: string,
  language: string,
  companyName: string,
  translationId: string,
  isErrorConsent: boolean,
  transaction: ITransaction,
  existingConsents: Map<number, string[]>,
  signatureWorkflowSteps: ISignatureWorkflowStep[],
  acceptConsent: (event: any) => void,
  addConsent: (elt: HTMLElement) => void,
}

export class ConsentCheckbox extends Component<IConsentCheckboxProps> {
  
  public render(): JSX.Element {
    const elements: JSX.Element[] = [];
    if (this.props.signatureWorkflowSteps) {
      const checkboxes = this.props.signatureWorkflowSteps[this.props.step].checkbox;
      if(checkboxes) {
        let counter = 0;
        checkboxes.forEach((label) => {
          elements.push(
            <DefaultCheckbox
              label={label}
              counter={counter}
              translationId={this.props.translationId}
              companyName={this.props.companyName}
              isErrorConsent={this.props.isErrorConsent}
              isCheckboxChecked={isConsentCheck(this.props.step, this.props.consent, label, true, this.props.existingConsents)}
              isBasedOnSignatureWorkflow={true}
              acceptConsent={(event: any) => this.props.acceptConsent(event)}
              addConsent={(elt: HTMLElement) => this.props.addConsent(elt)}
            />
          )
          counter++;
        })
      }
    }
    return <div>{elements}</div>
  }
}
