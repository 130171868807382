import { IWidgetConfigurationProps, withWidgetConfiguration } from '@sgwt-widget/core';
import { SubscriptionHandle } from '@sgwt-widget/core/lib/bus/Bus';
import { Component, h } from 'preact';
import { IntlProvider } from 'preact-i18n';
import { connect } from 'preact-redux';
import { Action } from 'redux';
import { ICounterpartSignatory } from '../../../common/domain/ICounterpartSignatory';
import { CounterpartType, ISignatureCounterpart } from '../../../common/domain/ISignatureCounterpart';
import { ITransaction, ITransactionStatus } from '../../../common/domain/ITransaction';
import { ITransactionAnnex } from '../../../common/domain/ITransactionAnnex';
import { CORPORATE_VALIDATOR, INTERMEDIATE_VALIDATOR } from '../../../common/domain/ITransactionValidator';
import { IProofOfConsent } from '../../../common/domain/IProofOfConsent';
import { ITransactionDocument } from '../../../common/domain/TransactionDocument';
import { IUser } from '../../../common/domain/User';
import { getMyInformation } from '../../../common/services/ContactService';
import { getExistingPoc, getLastPocVersion, pocMustBeSigned, } from '../../../common/services/ProofOfConsentService';
import {
  findTransaction,
  findTransactionByToken,
  findInformationToCreatePoc
} from '../../../common/services/TransactionService';
import {
  getCurrentUser,
  isOtpMandatoryForSignatory,
  isUserValidSignatory,
  isUserValidValidator
} from '../../../common/services/UserService';
import { changeLanguage, currentUserIsIntermediateValidator, transactionLoaded } from '../../actions';
import { loadDocuments } from '../../actions/IDocumentAction';
import { canDisplayPocLetterModal, initializeNonExistingPoc, pocIsCreated, pocSigned } from '../../actions/IPocAction';
import { currentUserIsSignatory, setCurrentUserPhoneNumber, currentUserIsValidator, otpMandatory, setCurrentUser } from '../../actions/IUserAction';
import * as definitionEn from '../../i18n/en.json';
import * as definitionFr from '../../i18n/fr.json';
import * as definitionDe from '../../i18n/de.json';
import { ApplicationState } from '../../states/ApplicationState';
import { css } from '../../styles';
import ForwardToSignatoriesPage from '../forward-to-signatories-page/ForwardToSignatoriesPage';
import SignaturePage from '../signature-page/SignaturePage';
import PocLetterModal from "../poc-letter-modal/PocLetterModal";
import { Provider } from "../../../common/domain/Provider";
import { IProofOfConsentInformation } from "../../../common/domain/IProofOfConsentInformation";
import { getCurrentSignatoryPhoneNumber, isTransactionSigned } from '../../../common/helpers/transactionHelper';

interface IAppState {
  isTransactionCancelled: boolean;
  isTransactionDenied: boolean;
  inError: boolean;
  currentUser: IUser;
  isPocAlreadySigned: boolean;
  transaction: ITransaction | undefined;
  pocIdTransaction: number;
  allDocumentsRead: boolean;
  signatureProcessing: boolean;
  documentsCount: number;
}

export interface IAppProps {
  user: IUser;
  pocId: number;
  language: string;
  isPocSigned: boolean;
  isForwarding: boolean;
  transactionId: number;
  forcePocDisplay: boolean;
  allDocumentsRead: boolean;
  signatureProcessing: boolean;
  initializePoc: () => void;
  pocIsCreated: (id: number) => void;
  currentUserLoaded: (user: IUser) => void;
  changeLanguage: (language: string) => void;
  otpMandatory: (isMandatory: boolean) => void;
  currentUserIsValidator: (valid: boolean) => void;
  currentUserIsSignatory: (valid: boolean) => void;
  setCurrentUserPhoneNumber: (phoneNumber?: string) => void;
  currentUserIsIntermediateValidator: (valid: boolean) => void;
  pocSigned: (sign: boolean, pocId: number | undefined) => void;
  transactionLoaded: (transaction: ITransaction | undefined) => void;
  canDisplayPocLetterModal: (isLetterModalDisplayed: boolean) => void;
  loadDocuments: (documents: ITransactionDocument[], documentAnnexes?: ITransactionAnnex[]) => void;
}

const App = withWidgetConfiguration(class extends Component<IAppProps & IWidgetConfigurationProps, IAppState> {
  private readonly definition: any = {};
  private languageSubscription: SubscriptionHandle;

  public async componentWillMount() {
    this.definition.fr = definitionFr;
    this.definition.en = definitionEn;
    this.definition.de = definitionDe;
    const currentUser: IUser = await getCurrentUser(this.props.widgetConfiguration);

    this.setState({
      currentUser,
      inError: false,
    });

    this.props.currentUserLoaded(currentUser);
    if (this.props.transactionId != undefined) {
      await this.setStatePocAlreadySigned(this.props.transactionId);
      this.languageSubscription = this.props.widgetConfiguration.bus.subscribe<string>(
        'global.language',
        async (language: string | undefined) => {
          if (language) {
            this.props.changeLanguage(language);
            if (this.isProofOfConsentMustBeSigned()) {
              await this.loadProofOfConsent(!!this.props.forcePocDisplay, this.props.transactionId);
            }
          }
        }
      );
      await this.initTransaction(this.props.transactionId);
    }
  }

  public async componentWillUnmount() {
    this.props.widgetConfiguration.bus.unsubscribe(this.languageSubscription);
  }

  private async setStatePocAlreadySigned(transactionId: number) {
    const isPocAlreadySigned: boolean = !await pocMustBeSigned(this.props.widgetConfiguration, transactionId);
    this.setState({
      isTransactionCancelled: false,
      isTransactionDenied: false,
      inError: false,
      allDocumentsRead: this.props.allDocumentsRead,
      signatureProcessing: this.props.signatureProcessing,
      isPocAlreadySigned
    });
  }

  public async initTransaction(transactionId: number) {
    if (this.isProofOfConsentMustBeSigned()) {
      await this.loadProofOfConsent(!!this.props.forcePocDisplay, transactionId);
    } else {
      this.props.pocSigned(true, undefined);
      await this.loadNewTransaction(transactionId);
    }
  }

  private isProofOfConsentMustBeSigned() {
    return this.props.forcePocDisplay || !this.state.isPocAlreadySigned;
  }

  private loadProofOfConsent(forcePocDisplay: boolean, transactionId: number) {
    this.setState({
      isPocAlreadySigned: false,
    });
    return getExistingPoc(this.props.widgetConfiguration, transactionId)
      .then((existingPoc: ITransaction) => {
        this.props.pocIsCreated(existingPoc.id);
        const pocIsSigned = isTransactionSigned(existingPoc);
        this.props.pocSigned(pocIsSigned, existingPoc.id);
        this.props.canDisplayPocLetterModal(!pocIsSigned);
        return this.loadNewTransaction(existingPoc.id);
      })
      .catch(() => getLastPocVersion(this.props.widgetConfiguration, this.props.language.toUpperCase(), this.props.transactionId)
        .then((proofOfConsent: IProofOfConsent) => {
          if (proofOfConsent && !this.state.isPocAlreadySigned) {
            this.props.initializePoc();
            this.props.pocSigned(false, undefined);
            const documents = proofOfConsent.documents;
            this.props.loadDocuments(documents);
            const proofOfConsentTransaction: ITransaction = {
              id: 0,
              signatureCounterparts: [],
              documentsForSignature: documents,
              documentsForInformation: [],
              status: ITransactionStatus.PENDING_FOR_EXTERNAL_SIGNATORY,
              signatureProvider: Provider.MORPHO,
              signatureLanguage: this.props.language.toUpperCase(),
            };

            const currentUrl: string = window.location.href;
            if (currentUrl.includes('/p/')) {
              const urlParam = currentUrl.split('/p/');
              const token = urlParam[1].split('/')[0];
              return this.buildProofOfConsentSignatoryByToken(proofOfConsentTransaction, token);
            }
            return this.buildProofOfConsentByInitialTransaction(proofOfConsentTransaction, transactionId, forcePocDisplay);
          }
        })
        .catch(() => this.setState({ inError: true }))
      );
  }

  private async buildProofOfConsentByInitialTransaction(proofOfConsent: ITransaction, initialTransactionId: number, forcePocDisplay: boolean) {
    const initialInformation: IProofOfConsentInformation = await findInformationToCreatePoc(this.props.widgetConfiguration, initialTransactionId);
    await getMyInformation(this.props.widgetConfiguration)
      .then((signatory: ICounterpartSignatory) => {
        signatory.phoneNumber = getCurrentSignatoryPhoneNumber(signatory, initialInformation);
        signatory.organization = initialInformation.clientName;
        this.setCurrentUserAsProofOfConsentSignatory(signatory, proofOfConsent);
        this.props.transactionLoaded(proofOfConsent);
        this.props.canDisplayPocLetterModal(!forcePocDisplay);
      })
  }

  private async buildProofOfConsentSignatoryByToken(proofOfConsentTransaction: ITransaction, token: string) {
    const transaction: ITransaction = await findTransactionByToken(this.props.widgetConfiguration, token);
    const externalCounterparts: ISignatureCounterpart[] =
      transaction.signatureCounterparts
        .filter(counterpart => counterpart.type = CounterpartType.EXTERNAL);
    const currentUserSignatories: ICounterpartSignatory[] =
      this.getcurrentUserSignatories(externalCounterparts);
    if (currentUserSignatories != undefined && currentUserSignatories.length > 0) {
      const currentSignatory = currentUserSignatories[0];
      this.setCurrentUserAsProofOfConsentSignatory(currentSignatory, proofOfConsentTransaction);
      this.props.transactionLoaded(proofOfConsentTransaction);
      this.props.canDisplayPocLetterModal(true);
    }
  }

  private getcurrentUserSignatories(externalCounterparts: ISignatureCounterpart[]) {
    return externalCounterparts
      .filter(counterpart =>
        counterpart.counterpartSignatories
          .find(signatory => signatory.identifier === this.state.currentUser.username))
      .map(counterpart => counterpart.counterpartSignatories)
      .reduce((previousSignatories, currentSignatories) => previousSignatories.concat(currentSignatories));
  }

  private setCurrentUserAsProofOfConsentSignatory(currentSignatory: ICounterpartSignatory, proofOfConsentTransaction: ITransaction) {
    this.props.currentUserIsSignatory(true);
    this.props.setCurrentUserPhoneNumber(currentSignatory.phoneNumber);
    proofOfConsentTransaction.signatureCounterparts = [
      {
        counterpartSignatories: [
          currentSignatory
        ],
        type: 'EXTERNAL',
        name: currentSignatory.organization
      } as ISignatureCounterpart
    ];
  }

  public async loadNewTransaction(transactionId: number) {
    try {
      this.setState({ transaction: undefined });
      const transactionResponse: ITransaction = await findTransaction(this.props.widgetConfiguration, transactionId);
      this.setState({ transaction: transactionResponse });
      if (this.isTransactionCancelled(transactionResponse)) {
        this.setState({ isTransactionCancelled: true });
        return;
      }
      else if (this.isTransactionDenied(transactionResponse)) {
        this.setState({ isTransactionDenied: true });
        return;
      }
      this.props.transactionLoaded(transactionResponse);
      this.props.changeLanguage(this.convertSignatureLanguage(transactionResponse.signatureLanguage));
      const documents = transactionResponse.documentsForSignature;
      documents.sort((d1, d2) => d1.id - d2.id);
      const documentAnnexes = transactionResponse.documentsForInformation;
      documentAnnexes.sort((d1, d2) => d1.id - d2.id);
      this.setState({ documentsCount: documents.length + documentAnnexes.length });
      this.props.loadDocuments(documents, documentAnnexes);
      this.props.otpMandatory(isOtpMandatoryForSignatory(this.props.user, transactionResponse));
      const { isSignatory, phoneNumber } = isUserValidSignatory(this.props.user, transactionResponse);
      this.props.currentUserIsSignatory(isSignatory);
      this.props.setCurrentUserPhoneNumber(phoneNumber);
      if (transactionResponse.status === ITransactionStatus.PENDING_FOR_VALIDATION) {
        this.props.currentUserIsValidator(isUserValidValidator(this.props.user, transactionResponse, CORPORATE_VALIDATOR));
      } else if (transactionResponse.status === ITransactionStatus.PENDING_FOR_INTERMEDIATE_VALIDATOR) {
        this.props.currentUserIsIntermediateValidator(isUserValidValidator(this.props.user, transactionResponse, INTERMEDIATE_VALIDATOR));
      }
    } catch (ex) {
      console.error(ex);
      this.setState({ inError: true });
    }
  }

  public async componentWillReceiveProps(nextProps: IAppProps) {
    if (nextProps.forcePocDisplay !== this.props.forcePocDisplay) {
      await this.loadProofOfConsent(!!nextProps.forcePocDisplay, nextProps.transactionId);
    }
    if (nextProps.pocId && nextProps.isPocSigned && !nextProps.allDocumentsRead && nextProps.transactionId) {
      this.setState({
        isPocAlreadySigned: nextProps.isPocSigned
      });
      await this.loadNewTransaction(nextProps.transactionId);
    }
    if (nextProps.transactionId && nextProps.transactionId !== this.props.transactionId) {
      await this.setStatePocAlreadySigned(nextProps.transactionId)
      await this.initTransaction(nextProps.transactionId);
    }

    this.languageSubscription = this.props.widgetConfiguration.bus.subscribe<string>(
      'global.language',
      async (language: string | undefined) => {
        if (language) {
          this.props.changeLanguage(language);
        }
      }
    );
  }

  private isTransactionCancelled(transaction: ITransaction): boolean {
    return (transaction.status === ITransactionStatus.CANCELLED_BY_ADMIN
      || transaction.status === ITransactionStatus.CANCELLED_BY_MIDDLE_OFFICE) ? true : false;
  }

  private isTransactionDenied(transaction: ITransaction): boolean {
    return (transaction.status === ITransactionStatus.DENIED_BY_INTERNAL_SIGNATORY
      || transaction.status === ITransactionStatus.DENIED_BY_EXTERNAL_SIGNATORY);
  }

  private convertSignatureLanguage(language: string): string {
    switch (language) {
      case "FRENCH":
        return "fr";
      case "GERMAN":
        return "de";
      default:
        return "en"
    }
  }

  private renderSignaturePage(): JSX.Element {
    if (this.props.isForwarding) {
      return (<ForwardToSignatoriesPage />);
    }
    return (
      <div className={css("h-100")}>
        <SignaturePage />
        <PocLetterModal />
      </div>
    );
  }

  public render(): JSX.Element {
    if (this.state.isTransactionCancelled || this.state.inError || this.state.isTransactionDenied) {
      return (
        <section className={css("container h-100")}>
          <section className={css("row")}>
            <section className={css("col")}>
              <h2>
                {(this.state.isTransactionCancelled || this.state.isTransactionDenied) ? this.definition[this.props.language]["signature"]["cancelled-message"] : this.definition[this.props.language]["signature"]["error-message"]}
              </h2>
            </section>
          </section>
        </section>
      );
    }
    return (
      <IntlProvider definition={this.definition}>
        {this.renderSignaturePage()}
      </IntlProvider>
    );
  }
});

function mapStateToProps(state: ApplicationState) {
  return {
    allDocumentsRead: state.signatureState.allDocumentsRead,
    isPocSigned: state.pocState.isPocSigned,
    forcePocDisplay: state.pocState.forceDisplay,
    signatureProcessing: state.signatureState.processing,
    transactionId: state.transactionIdState.transactionId,
    pocId: state.pocState.pocId,
    language: state.languageState.language,
    user: state.userState.user,
    isForwarding: state.intermediateValidatorState.forwarding,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  changeLanguage: (language: string) => dispatch(changeLanguage(language)),
  currentUserIsSignatory: (valid: boolean, phone?: string) => dispatch(currentUserIsSignatory(valid)),
  setCurrentUserPhoneNumber: (phone?: string) => dispatch(setCurrentUserPhoneNumber(phone)),
  currentUserLoaded: (user: IUser) => dispatch(setCurrentUser(user)),
  loadDocuments: (documents: ITransactionDocument[], documentAnnexes?: ITransactionAnnex[]) =>
    dispatch(loadDocuments(documents, documentAnnexes)),
  transactionLoaded: (transaction: ITransaction) => dispatch(transactionLoaded(transaction)),
  pocIsCreated: (pocId: number) => dispatch(pocIsCreated(pocId)),
  pocSigned: (isPocSigned: boolean, pocId: number) => dispatch(pocSigned(isPocSigned, pocId)),
  initializePoc: () => dispatch(initializeNonExistingPoc()),
  canDisplayPocLetterModal: (isLetterModalDisplayed: boolean) => dispatch(canDisplayPocLetterModal(isLetterModalDisplayed)),
  currentUserIsValidator: (valid: boolean) => dispatch(currentUserIsValidator(valid)),
  currentUserIsIntermediateValidator: (valid: boolean) => dispatch(currentUserIsIntermediateValidator(valid)),
  otpMandatory: (isMandatory: boolean) => dispatch(otpMandatory(isMandatory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App as any);