import { ISelectTransactionAction, SELECT_TRANSACTION_TYPE } from "../actions";
import { ITransactionIdState } from "../states/ITransactionIdState";

export function selectTransactionReducer(
  state: ITransactionIdState = { transactionId: undefined },
  action: ISelectTransactionAction
): ITransactionIdState {
  switch (action.type) {
    case SELECT_TRANSACTION_TYPE:
      if (action.transactionId) {
        return Object.assign({}, state, {
          transactionId: action.transactionId,
        });
      }
  }
  return state;
}
