import { CHANGE_LANGUAGE_TYPE, IChangeLanguageAction } from "../actions";
import { ILanguageState } from "../states/ILanguageState";

export function changeLanguageReducer(
  state: ILanguageState = { language: "en" },
  action: IChangeLanguageAction
): ILanguageState {
  switch (action.type) {
    case CHANGE_LANGUAGE_TYPE:
      if(action.language) {
        return Object.assign({}, state, {
          language: action.language.toLowerCase() 
        });
      }   
  }
  return state;
}
