import {
  IS_OTP_MANDATORY_FOR_SIGNATORY_TYPE, IS_USER_INTERMEDIATE_VALIDATOR_TYPE,
  IS_USER_SIGNATORY_TYPE, IS_USER_VALIDATOR_TYPE,
  IUserAction,
  SET_CURRENT_USER_TYPE,
  SET_USER_PHONE_NUMBER_TYPE,
} from '../actions/IUserAction';
import { IUserState } from "../states/IUserState";

export function currentUserLoadedReducer(
  state: IUserState = { user: undefined, isValidSignatory: false, isValidValidator: false, isOtpMandatory: true},
  action: IUserAction
): IUserState {
  switch (action.type) {
    case SET_CURRENT_USER_TYPE:
      if (action.user) {
        return Object.assign({}, state, { user: action.user });
      }
      break;
    case IS_USER_SIGNATORY_TYPE:
      return Object.assign({}, state, {isValidSignatory: action.isUserSignatory});
    case IS_USER_VALIDATOR_TYPE:
      return Object.assign({}, state, {isValidValidator: action.isUserValidator});
    case IS_USER_INTERMEDIATE_VALIDATOR_TYPE:
      return Object.assign({}, state, {isValidIntermediateValidator: action.isUserIntermediateValidator});
    case IS_OTP_MANDATORY_FOR_SIGNATORY_TYPE:
      return Object.assign({}, state, {isOtpMandatory: action.isOtpMandatory});
    case SET_USER_PHONE_NUMBER_TYPE:
        return Object.assign({}, state, {phoneNumber: action.phoneNumber});

  }
  return state;
}
