import {Action} from "redux";
import {IUser} from "../../common/domain/User";

export const SET_CURRENT_USER_TYPE = "SET_CURRENT_USER";
export const SET_USER_PHONE_NUMBER_TYPE = "SET_USER_PHONE_NUMBER_TYPE";
export const IS_USER_SIGNATORY_TYPE = "IS_USER_SIGNATORY";
export const IS_USER_VALIDATOR_TYPE = "IS_USER_VALIDATOR";
export const IS_USER_INTERMEDIATE_VALIDATOR_TYPE = "IS_USER_INTERMEDIATE_VALIDATOR_TYPE";
export const IS_OTP_MANDATORY_FOR_SIGNATORY_TYPE = "IS_OTP_MANDATORY_FOR_SIGNATORY";

export interface IUserAction extends Action<string> {
  isUserSignatory?: boolean;
  isUserValidator?: boolean;
  isUserIntermediateValidator?: boolean;
  phoneNumber?: string;
  user?: IUser;
  isOtpMandatory?: boolean;
}

export function setCurrentUser(user: IUser): IUserAction {
  return {
    type: SET_CURRENT_USER_TYPE,
    user,
  };
}

export function setCurrentUserPhoneNumber( phoneNumber?: string): IUserAction {
  return {
    type: SET_USER_PHONE_NUMBER_TYPE,
    phoneNumber,
  };
}

export function currentUserIsSignatory(valid: boolean): IUserAction {
  return {
    isUserSignatory: valid,
    type: IS_USER_SIGNATORY_TYPE,
  };
}

export function currentUserIsValidator(valid: boolean): IUserAction {
  return {
    isUserValidator: valid,
    type: IS_USER_VALIDATOR_TYPE,
  };
}

export function currentUserIsIntermediateValidator(valid: boolean): IUserAction {
  return {
    isUserIntermediateValidator: valid,
    type: IS_USER_INTERMEDIATE_VALIDATOR_TYPE,
  };
}

export function otpMandatory(isMandatory: boolean): IUserAction {
  return {
    isOtpMandatory: isMandatory,
    type: IS_OTP_MANDATORY_FOR_SIGNATORY_TYPE,
  }
}
