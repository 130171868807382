import { Component, h } from "preact";
import { connect } from "preact-redux";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import { Action } from "redux";
import { dispatchDownloadStatus } from "../../actions";
import { DocumentDownloadStatus } from "../../../common/domain/DocumentDownloadStatus";
import { Origin } from "../../../common/domain/Origin";
import { Provider } from "../../../common/domain/Provider";
import { isMigratedDocusignDocument } from "../../../common/helpers/documentHelper";

export interface IDownloadButtonProps {
  documentUrl: string;
  dispatchDownloadRequest: () => void;
  isDocumentLoaded: boolean;
  isMigratedDocusignDocument: boolean;
}

class DownloadButton extends Component<IDownloadButtonProps> {
  private downloadDocument() {
    this.props.dispatchDownloadRequest();
  }

  public render(): JSX.Element {
    return (
      <article>
        <button
          disabled={
            !this.props.isMigratedDocusignDocument &&
            !this.props.isDocumentLoaded
          }
          className={`${css("btn btn-lg btn-flat-primary")} not-mobile-article`}
          onClick={() => this.downloadDocument()}
        >
          <em className={`material-icons`}>file_download</em>
        </button>
        <button
          disabled={
            !this.props.isMigratedDocusignDocument &&
            !this.props.isDocumentLoaded
          }
          className={`${css("btn btn-md btn-flat-primary")} mobile-article`}
          onClick={() => this.downloadDocument()}
        >
          <em className={`material-icons`}>file_download</em>
        </button>
      </article>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    documentUrl: state.documentState.currentDocumentUrl,
    isDocumentLoaded: state.documentState.loaded,
    isMigratedDocusignDocument: isMigratedDocusignDocument(state),
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  dispatchDownloadRequest: () => {
    dispatch(dispatchDownloadStatus(DocumentDownloadStatus.REQUESTED));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadButton as any);
