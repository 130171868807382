import {
  CANCEL_SIGNATURE_REJECTION_TYPE,
  CONFIRM_SIGNATURE_REJECTION_TYPE,
  IRejectionAction,
  REJECTING_SIGNATURE_TYPE,
} from "../actions/IRejectionAction";
import { IRejectionState } from "../states/IRejectionState";

export function rejectionReducer(
  state: IRejectionState = { rejected: false, rejecting: false },
  action: IRejectionAction
): IRejectionState {
  switch (action.type) {
    case REJECTING_SIGNATURE_TYPE:
    case CANCEL_SIGNATURE_REJECTION_TYPE:
      return Object.assign({}, state, { rejecting: action.rejecting });
    case CONFIRM_SIGNATURE_REJECTION_TYPE:
      return Object.assign({}, state, { rejected: action.rejected });
  }
  return state;
}
