import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { css } from "../../styles";

export interface IDefaultCheckboxProps {
  label: string,
  counter?: number,
  companyName: string,
  translationId: string,
  isErrorConsent: boolean,
  isCheckboxChecked: boolean,
  isBasedOnSignatureWorkflow: boolean,
  acceptConsent: (event: any) => void,
  addConsent: (elt: HTMLElement) => void,
}

export class DefaultCheckbox extends Component<IDefaultCheckboxProps> {
  public render(): JSX.Element {
    return (
      <div>
        <input
          type="checkbox"
          className={css("form-check-input")}
          id={`consent-${this.props.counter}`}
          onChange={(e: any) => this.props.acceptConsent(e)}
          checked={this.props.isCheckboxChecked}
        />
        <label
          className={`${css("form-check-label font-weight-bold")}
            ${this.props.isErrorConsent ? "text-danger" : ""} pointer`}
          for={`consent-${this.props.counter}`}
          ref={(elt: HTMLElement) => (this.props.addConsent(elt))}
        >
        {this.props.isBasedOnSignatureWorkflow ?
          this.props.label.replace(/{{company}}/g, this.props.companyName)
          :
          <MarkupText
            id={this.props.translationId}
            fields={{
              company: this.props.companyName,
            }}
          >
            I declare that I am authorized to sign on behalf of{" "}
            {this.props.companyName}
          </MarkupText>
        }
      </label>
    </div>
    )
  }
}
