import { ICounterpartSignatory } from "../domain/ICounterpartSignatory";
import { ISignatureCounterpart } from "../domain/ISignatureCounterpart";
import { ITransaction } from "../domain/ITransaction";

const SG_COMPANY_NAME = "SOCIÉTÉ GÉNÉRALE";

export function findCurrentSignatoryCounterpartName(transaction: ITransaction, currentSignatory?: ICounterpartSignatory) {
  if (!currentSignatory) {
    throw new Error ("Current signatory is undefined");
  }

  const sortedCounterparts = [...transaction.signatureCounterparts]
    .sort(
      (left: ISignatureCounterpart, right: ISignatureCounterpart) =>
        left.order - right.order
    );

  const signatureCounterpart = sortedCounterparts
    .find(counterpart => !!counterpart.counterpartSignatories
      .find(signatory => signatory.identifier === currentSignatory!.identifier && !signatory.hasSigned)
    );

  if (!signatureCounterpart) {
    throw new Error("Cannot find signatory counterpart");
  }
  if (!signatureCounterpart.name && signatureCounterpart.type === "INTERNAL") {
    return SG_COMPANY_NAME;
  }
  return signatureCounterpart.name!;
}
