import "./SpinnerWidget.scss";

import { Component, h } from "preact";

interface ISpinnerWidgetProps {
  size?: number
}

export class SpinnerWidget extends Component<ISpinnerWidgetProps, {}> {
  public render(): JSX.Element {
    return (
      <svg
        className="spinner"
        width={this.props.size ? this.props.size + "px" : "65px"}
        height={this.props.size ? this.props.size + "px" : "65px"}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    );
  }
}