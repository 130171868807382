import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { validatePhoneNumber } from "../../actions/ISignatureAction";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import { isMobilePhoneNumber } from "../../../common/helpers/otpHelper";

interface IConfirmPhoneModalState {
  open: boolean;
}

export interface IConfirmPhoneModalProps {
  checkPhoneNumber: boolean;
  language: string;
  phoneNumber: string;
  validatePhoneNumber: (isValid: boolean) => void;
}

class ConfirmPhoneModal extends Component<
  IConfirmPhoneModalProps,
  IConfirmPhoneModalState
  > {
  public async componentDidMount() {
    this.handleModalState(this.props.checkPhoneNumber);
  }

  public async componentWillReceiveProps(nextProps: IConfirmPhoneModalProps) {
    this.handleModalState(nextProps.checkPhoneNumber);
  }

  public async handleModalState(checkPhoneNumber: boolean) {
    this.setState({ open: checkPhoneNumber });
  }

  public confirm() {
    this.props.validatePhoneNumber(true);
    this.setState({ open: false });
  }

  public requestOtpByEmail() {
    this.props.validatePhoneNumber(false);
    this.setState({ open: false });
  }

  public render(): JSX.Element {
    if (this.state.open) {
      if (!isMobilePhoneNumber(this.props.phoneNumber)) {
        return (
          <article>
            <div id={'modal'}
                className={`${css("modal fade show")} opened`}
                tabIndex={-1}
                role="dialog"
            >
              <div
                className={css("modal-dialog modal-dialog-centered")}
                role="document"
              >
                <div className={css("modal-content shadow-max")}>
                  <div className={css("modal-header")}>
                    <h3 className={css("modal-title")}>
                      <MarkupText
                        id={this.props.language + ".confirm-phone-modal.title"}
                      >
                        Confirmation
                      </MarkupText>
                    </h3>
                  </div>
                  <div className={css("modal-body")}>
                    <article>
                      <MarkupText
                        id={this.props.language + ".confirm-phone-modal.message"}
                        fields={{ phoneNumber: this.props.phoneNumber }}
                      >
                        Is <strong>{this.props.phoneNumber}</strong> your correct phone number ?
                      </MarkupText>
                    </article>
                  </div>
                  <div className={css("modal-footer")}>
                    <button
                      type="button"
                      className={css("btn btn-xl btn-default")}
                      data-dismiss="modal"
                      onClick={() => this.confirm()}
                    >
                      <MarkupText
                        id={this.props.language + ".confirm-phone-modal.yes"}
                      >
                        Oui
                      </MarkupText>
                    </button>
                    <button
                      type="button"
                      className={css("btn btn-xl btn-default")}
                      data-dismiss="modal"
                      onClick={() => this.requestOtpByEmail()}
                    >
                      <MarkupText
                        id={this.props.language + ".confirm-phone-modal.no"}
                      >
                        No, send me password by email
                      </MarkupText>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>
        );
      } else {
        this.confirm();
      }
    }
    return <article />;
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    checkPhoneNumber: state.signatureState.checkPhoneNumber,
    language: state.languageState.language,
    phoneNumber: state.userState.phoneNumber,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  validatePhoneNumber: (isValid: boolean) =>
    dispatch(validatePhoneNumber(isValid)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmPhoneModal as any);
