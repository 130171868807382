import { Component, h } from "preact";
import { connect } from "preact-redux";
import { Action } from "redux";
import {
  enterFullscreenMode,
  exitFullscreenMode,
} from "../../actions/IDocumentAction";
import { ApplicationState } from "../../states/ApplicationState";
import { FullscreenMode } from "../../states/IDocumentState";
import { css } from "../../styles";
import { Origin } from "../../../common/domain/Origin";
import { Provider } from "../../../common/domain/Provider";
import { isMigratedDocusignDocument } from "../../../common/helpers/documentHelper";

interface IFullscreenButtonState {
  isFullscreenEnabled: boolean;
}

export interface IFullscreenButtonProps {
  fullscreenMode: FullscreenMode;
  enterFullscreenMode: () => void;
  exitFullscreenMode: () => void;
  isMigratedDocusignDocument: boolean;
}

class FullscreenButton extends Component<
  IFullscreenButtonProps,
  IFullscreenButtonState
> {
  public async componentDidMount() {
    this.setState({
      isFullscreenEnabled: this.props.fullscreenMode === FullscreenMode.ON,
    });
  }

  public async componentWillReceiveProps(nextProps: IFullscreenButtonProps) {
    this.setState({
      isFullscreenEnabled: nextProps.fullscreenMode === FullscreenMode.ON,
    });
  }

  public exitFullscreen(): void {
    this.props.exitFullscreenMode();
    this.setState({
      isFullscreenEnabled: false,
    });
  }

  public enterFullscreen(): void {
    this.props.enterFullscreenMode();
    this.setState({
      isFullscreenEnabled: true,
    });
  }

  public render(): JSX.Element {
    if (this.state.isFullscreenEnabled) {
      return (
        <button
          className={css("btn btn-link px-0 ml-3")}
          onClick={(e: any) => this.exitFullscreen()}
        >
          <em className={`material-icons`}>crop_free</em>
        </button>
      );
    }
    return (
      <article>
        <button
          className={`${css(
            "btn btn-lg btn-lg btn-flat-primary"
          )} not-mobile-article`}
          disabled={this.props.isMigratedDocusignDocument}
          onClick={(e: any) => this.enterFullscreen()}
        >
          <em className={`material-icons`}>crop_free</em>
        </button>
        <button
          className={`${css("btn btn-md btn-flat-primary")} mobile-article`}
          disabled={this.props.isMigratedDocusignDocument}
          onClick={(e: any) => this.enterFullscreen()}
        >
          <em className={`material-icons`}>crop_free</em>
        </button>
      </article>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    isMigratedDocusignDocument: isMigratedDocusignDocument(state),
    fullscreenMode: state.documentState.fullscreenMode,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  enterFullscreenMode: () => dispatch(enterFullscreenMode()),
  exitFullscreenMode: () => dispatch(exitFullscreenMode()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FullscreenButton as any);
