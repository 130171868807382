import { combineReducers } from "redux";
import { ApplicationState } from "../states/ApplicationState";
import { documentReducer } from "./DocumentReducer";
import { currentIntermediateValidatorReducer } from './IntermediateValidatorReducer';
import { changeLanguageReducer } from "./LanguageReducer";
import { rejectionReducer } from "./RejectionReducer";
import { signatureReducer } from "./SignatureReducer";
import { selectTransactionReducer } from "./TransactionIdReducer";
import { transactionLoadedReducer } from "./TransactionLoadedReducer";
import { currentUserLoadedReducer } from "./UserReducer";
import {currentPocStatusReducer} from "./PocReducer";
import {currentValidatorReducer} from "./ValidatorReducer";
import {eventReducer} from "./EventReducer";
import {focusOnDocumentReducer} from "./FocusOnDocumentReducer";

export default combineReducers<ApplicationState>({
  documentState: documentReducer,
  languageState: changeLanguageReducer,
  rejectionState: rejectionReducer,
  signatureState: signatureReducer,
  transactionIdState: selectTransactionReducer,
  focusOnDocumentState: focusOnDocumentReducer,
  transactionState: transactionLoadedReducer,
  userState: currentUserLoadedReducer,
  pocState: currentPocStatusReducer,
  validatorState: currentValidatorReducer,
  intermediateValidatorState: currentIntermediateValidatorReducer,
  eventState: eventReducer
});
