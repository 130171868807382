import { Action } from "redux";

export const CHANGE_LANGUAGE_TYPE = "CHANGE_LANGUAGE";

export interface IChangeLanguageAction extends Action<string> {
  language: string;
}

export function changeLanguage(language: string): IChangeLanguageAction {
  return {
    language,
    type: CHANGE_LANGUAGE_TYPE,
  };
}
