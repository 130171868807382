import { WidgetConfiguration } from "@sgwt-widget/core";
import { ITransaction } from "../domain/ITransaction";
import { ajaxCall } from "./AjaxService";
import { IProofOfConsentInformation } from "../domain/IProofOfConsentInformation";

export async function findTransaction(
  widgetConfiguration: WidgetConfiguration,
  transactionId: number
): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    url: `${serviceUrl}/rest/transaction/light/${transactionId}`,
    crossDomain: true,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function findTransactionByToken(
  widgetConfiguration: WidgetConfiguration,
  token: string
): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    url: `${serviceUrl}/rest/transaction/light/with-token/${token}`,
    crossDomain: true,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function findInformationToCreatePoc(
  widgetConfiguration: WidgetConfiguration,
  transactionId: number
): Promise<IProofOfConsentInformation> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<
    ISignatureConfig
  >()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    url: `${serviceUrl}/rest/transaction/init-poc/${transactionId}`,
    crossDomain: true,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}
