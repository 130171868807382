import { parsePhoneNumber } from "libphonenumber-js/max";
import { ITransaction } from "../domain/ITransaction";
import { IUser } from "../domain/User";
import { OtpMode } from "../domain/IOtp";

export function isMobilePhoneNumber(phoneNumber: string): boolean {
  if(phoneNumber && phoneNumber.toLowerCase() !== "no phone") {
    try {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    return parsedPhoneNumber.getType() === "MOBILE";
    } catch(e) {
      console.error('Enable to parse user phone number')
    }
  }
  return false
}

export function getCurrentSignatoryPhoneNumber(transaction: ITransaction, user: IUser): string {
  const currentCounterpartOrder = transaction.currentCounterpartOrder;
  const currentCounterpart = currentCounterpartOrder && transaction.signatureCounterparts.find((counterpart) => currentCounterpartOrder === counterpart.order);
  if(currentCounterpart && currentCounterpartOrder) {
    const currentSignatory = currentCounterpart.counterpartSignatories.find((counterpart) => counterpart.email === user.username);
    return (currentSignatory && currentSignatory.phoneNumber) as string;
  }
  return "no phone";
}

export function getCurrentSignatoryOTPMethod(phoneNumber: string, isPublicSignatory: boolean): OtpMode {
  if(isPublicSignatory) {
    return OtpMode.SMS;
  }

  if(phoneNumber === "no phone") {
    return OtpMode.EMAIL;
  }

  if(isMobilePhoneNumber(phoneNumber)) {
    return OtpMode.SMS;
  }

  return OtpMode.EMAIL;
}

export function getOtpValue(otpMode: OtpMode, phoneNumber: string, email: string): string {
  if([OtpMode.MOBILE, OtpMode.SMS].includes(otpMode)) {
    return phoneNumber;
  }
  return email
}