import {Component, h} from "preact";
import {Text} from "preact-i18n";

import {ICounterpartContact} from "../../../../../common/domain/ICounterpartContact";
import {ISignatureCounterpart} from "../../../../../common/domain/ISignatureCounterpart";
import {css} from "../../../../styles";

interface IState {
  counterparts: ISignatureCounterpart[];
}

export interface IIContactOnlyComponentProps {
  counterpart: ISignatureCounterpart;
  language: string;
}

export class ContactOnlyComponent extends Component<IIContactOnlyComponentProps,
  IState> {

  public buildContactName(contact: ICounterpartContact): JSX.Element {
    return (
      <h5 class={css("font-weight-bold")}>
        {contact.firstname} {contact.lastname.toUpperCase()}
      </h5>
    );
  }

  public displayContacts(): JSX.Element[] {
    if (
      this.props.counterpart.counterpartContacts &&
      this.props.counterpart.counterpartContacts.length > 0
    ) {
      const contactsDisplay = this.props.counterpart.counterpartContacts
        .filter(
          (contact: ICounterpartContact) =>
            contact && contact.firstname && contact.lastname
        )
        .map((contact: ICounterpartContact) => (
          <article>{this.buildContactName(contact)}</article>
        ));
      if (contactsDisplay && contactsDisplay.length > 0) {
        return contactsDisplay;
      }
    }
    return [];
  }

  public render(): JSX.Element {
    return (
      <section>
        {this.displayContacts()}
      </section>
    );
  }
}
