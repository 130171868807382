import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { allDocumentsRead } from "../../actions/ISignatureAction";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";

interface IOverallContractsReviewState {
  documents: ITransactionDocument[];
  language: string;
  totalReadCompletion: number;
}

export interface IOverallContractsReviewProps {
  documents: ITransactionDocument[];
  language: string;
  isPocSigned: boolean;
  isValidValidator: boolean;
  isValidSignatory: boolean;
  signable: boolean;
  allDocumentsRead: () => void;
}

class OverallContractsReview extends Component<
  IOverallContractsReviewProps,
  IOverallContractsReviewState
> {
  public async componentDidMount() {
    if (this.props.documents) {
      this.setState({
        documents: this.props.documents,
        totalReadCompletion: this.totalReadCompletion(this.props.documents),
      });
    }
    if (this.props.language) {
      this.setState({ language: this.props.language });
    }
  }

  public async componentWillReceiveProps(
    nextProps: IOverallContractsReviewProps
  ) {
    if (nextProps.documents) {
      this.setState({
        documents: nextProps.documents,
        totalReadCompletion: this.totalReadCompletion(nextProps.documents),
      });
      if (this.state.totalReadCompletion === 100) {
        this.props.allDocumentsRead();
      }
    }
    if (nextProps.language) {
      this.setState({ language: nextProps.language });
    }
  }

  public totalReadCompletion(documents: ITransactionDocument[]): number {
    if (documents) {
      const documentLength = documents.filter(doc => doc.mustRead).length;
      const totalReadCompletion = documents
        .filter(doc => doc.mustRead)
        .map((doc: ITransactionDocument) => doc.readCompletion || 0)
        .reduce(
          (previousValue: number, currentValue: number) =>
            previousValue + currentValue,
          0
        );
      return Math.floor(totalReadCompletion / documentLength);
    }
    return 0;
  }

  public render(): JSX.Element {
    if ((this.props.isValidSignatory || this.props.isValidValidator || !this.props.isPocSigned) && this.props.signable) {
      return (
        <section className={css("mb-3")}>
          <h5 className={css(this.state.totalReadCompletion === 100
            ? "text-success"
            : (
              this.state.totalReadCompletion > 80 && this.state.totalReadCompletion < 100
                ? "text-warning"
                : "text-danger"
            ))}>
            {this.state.totalReadCompletion}%&nbsp;
            <MarkupText id={this.state.language + ".overall-contracts.title"}>
              full contracts
            </MarkupText>
          </h5>
          <div className={css("progress progress-sm")}>
            <div
              className={css(
                "progress-bar",
                this.state.totalReadCompletion === 100
                  ? "bg-success"
                  : (
                    this.state.totalReadCompletion > 80 && this.state.totalReadCompletion < 100
                      ? "bg-warning"
                      : "bg-danger"
                  )
              )}
              role={"progressbar"}
              style={`width: ${this.state.totalReadCompletion}%`}
              aria-valuenow={`${this.state.totalReadCompletion}`}
              aria-valuemin={"0"}
              aria-valuemax={"100"}
            />
          </div>
        </section>
      );
    }
    else {
      return (<section />);
    }
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    documents: state.documentState.documents,
    language: state.languageState.language,
    isValidValidator: state.userState.isValidValidator,
    isPocSigned: state.pocState.isPocSigned,
    signable: state.signatureState.signable,
    isValidSignatory: state.userState.isValidSignatory
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  allDocumentsRead: () => dispatch(allDocumentsRead(true)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverallContractsReview as any);
