import {FOCUS_ON_DOCUMENT_TYPE, IFocusOnDocumentAction} from "../actions";
import {IFocusOnDocumentState} from "../states/IFocusOnDocumentState";

export function focusOnDocumentReducer(
  state: IFocusOnDocumentState = {},
  action: IFocusOnDocumentAction
): IFocusOnDocumentState {
  switch (action.type) {
    case FOCUS_ON_DOCUMENT_TYPE:
      if (action.document) {
        return Object.assign({}, state, {
          focusOnDocument: action.document,
        });
      }
  }
  return state;
}
