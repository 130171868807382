import {IPocState} from "../states/IPocState";
import {
  DISPLAY_POC_LETTER_TYPE,
  INITIALIZE_POC_TYPE,
  IPocAction,
  LOAD_PROOF_OF_CONSENT_TYPE,
  POC_IS_CREATED_TYPE,
  POC_IS_SIGNED_TYPE
} from "../actions/IPocAction";

export function currentPocStatusReducer(state: IPocState = {
                                          isPocSigned: undefined,
                                          pocId: undefined,
                                          displayPocLetter: false,
                                          forceDisplay: false
                                        },
                                        action: IPocAction): IPocState {
  switch (action.type) {
    case POC_IS_SIGNED_TYPE:
      if (action.isPocSigned) {
        return Object.assign({}, state, {isPocSigned: true, pocId: action.pocId});
      } else {
        return Object.assign({}, state, {isPocSigned: false, pocId: action.pocId});
      }
    case POC_IS_CREATED_TYPE:
      return Object.assign({}, state, {pocId: action.pocId});
    case INITIALIZE_POC_TYPE:
      return Object.assign({}, state, {isPocSigned: false, pocId: undefined});
    case DISPLAY_POC_LETTER_TYPE:
      return Object.assign({}, state, {displayPocLetter: action.displayPocLetter});
    case LOAD_PROOF_OF_CONSENT_TYPE:
      return Object.assign({}, state, {displayPocLetter: action.displayPocLetter, forceDisplay: action.forceDisplay});
  }
  return state;
}
