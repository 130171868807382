import { ITransactionDocument, readOptionTypes } from "../../common/domain/TransactionDocument";
import { DocumentDownloadStatus } from "../../common/domain/DocumentDownloadStatus";

function detectMob(): boolean {
  return (window.innerWidth < window.innerHeight);
}

function getUpdatedScale(): number {
  return Number((window.innerWidth / window.innerHeight).toFixed(2));
}

export const DEFAULT_SCALE = detectMob() ? getUpdatedScale() : 1;

export enum FullscreenMode {
  ON,
  OFF,
}

export interface IDocumentState {
  currentDocumentId?: number;
  currentDocumentUrl?: string;
  currentDocumentReadOption?: readOptionTypes;
  documents: ITransactionDocument[];
  scale: number;
  fullscreenMode: FullscreenMode;
  isPrinting: boolean;
  loaded: boolean;
  downloadStatus?: DocumentDownloadStatus;
}
