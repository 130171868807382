import { Component, h } from "preact";
import Pagination from "../../../pagination/Pagination";
import PreviousButton from "../../../previous-button/PreviousButton";
import NextButton from "../../../next-button/NextButton";
import { css } from "../../../../styles";

interface IPaginationComponentProps {
  documentsCount: number
}

export class PaginationComponent extends Component<IPaginationComponentProps, {}> {
  public render(): JSX.Element {
    const elements = [<Pagination />];
    if (this.props.documentsCount > 1) {
      elements.push(<PreviousButton />);
      elements.unshift(<NextButton />);
    }
    return (
      <section className={`${css("col-2 col-lg justify-content-end")} pagination-buttons`}>
        {elements}
      </section>
    );
  }
}