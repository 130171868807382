import {IValidatorAction, VALIDATE_TRANSACTION_TYPE} from "../actions/IValidatorAction";
import {IValidatorState} from "../states/IValidatorState";

export function currentValidatorReducer(state: IValidatorState = {
                                          isSignatureValidated: false,
                                          openValidationModal: false
                                        },
                                        action: IValidatorAction): IValidatorState {
  switch (action.type) {
    case VALIDATE_TRANSACTION_TYPE:
      return Object.assign({}, state, {
        isSignatureValidated: action.isSignatureValidated,
        openValidationModal: action.openValidationModal
      });
  }
  return state;
}
