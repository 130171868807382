import {Component, h} from "preact";
import {ApplicationState} from "../../states/ApplicationState";
import {connect} from "preact-redux";
import {css} from "../../styles";
import {MarkupText, Text} from "preact-i18n";

interface IPocLetterModalState {
  open: boolean;
}

export interface IPocLetterModalProps {
  language: string;
  displayPocLetter: boolean;
}

class PocLetterModal extends Component<IPocLetterModalProps, IPocLetterModalState> {

  public async componentWillMount() {
    this.setState({open: false});
  }

  public async componentWillReceiveProps(nextProps: IPocLetterModalProps) {
    this.handleModalState(nextProps.displayPocLetter);
  }

  public handleModalState(shouldBeDisplayed: boolean): void {
    this.setState({open: shouldBeDisplayed});
  }

  public validateModal() {
    this.setState({open: false});
  }

  public render(): JSX.Element {
    if (this.state.open) {
      return (
        <article>
          <div id={'modal'}
            className={`${css("modal fade show")} opened`}
            tabIndex={-1}
            role="dialog">
            <div
              className={css("modal-dialog modal-dialog-centered modal-lg")}
              role="document">
              <div className={css("modal-content shadow-max")}>
                <div className={css("modal-header")}>
                  <h3 className={css("modal-title")}>
                    <MarkupText id={this.props.language + ".poc-letter-modal.letter.title"}>
                      Electronic execution of Société Générale’s documentation
                    </MarkupText>

                  </h3>
                </div>
                <div className={css("modal-body")}>
                  <div>
                    <MarkupText id={this.props.language + ".poc-letter-modal.letter.dear"}>
                      Dear Madam, dear Sir,
                    </MarkupText>
                  </div>
                  <br></br>
                  <div>
                    <MarkupText id={this.props.language + ".poc-letter-modal.letter.introduction"}>
                      We would like to inform you of Société Générale (“SG”)’s intention to progressively implement an
                      Electronic execution of Société Générale’s documentation using digital signatures (instead of the
                      current manual execution process) in respect of the documentation executed between SG and its
                      clients. Even though we strongly recommend the use of such electronic execution of Société
                      Générale’s documentation. Obviously, we allow clients to keep the current manual execution if they
                      so wish.
                    </MarkupText>
                    <br></br>

                    <MarkupText id={this.props.language + ".poc-letter-modal.letter.conclusion"}>
                      Please do not hesitate to contact us with any query or comment on the above or the attached form
                      of
                      electronic execution of Société Générale’s documentation agreement.
                    </MarkupText>
                  </div>
                  <br></br>
                  <div>
                    <MarkupText id={this.props.language + ".poc-letter-modal.letter.regards"}>
                      Sincerely yours,
                    </MarkupText>
                  </div>
                  <br></br>

                  <MarkupText id={this.props.language + ".poc-letter-modal.letter.signature"}>
                    Société Générale
                  </MarkupText>
                </div>
                <div className={css("modal-footer")}>
                  <button type="button"
                          className={css("btn btn-xl btn-primary")}
                          data-dismiss="modal"
                          onClick={() => this.validateModal()}>
                    <MarkupText id={this.props.language + ".poc-letter-modal.button"}>
                      Read and accept
                    </MarkupText>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </article>);
    }
    return <article/>;
  }

}

function mapStateToProps(state: ApplicationState) {
  return {
    language: state.languageState.language,
    displayPocLetter: state.pocState.displayPocLetter
  }
}

export default connect(mapStateToProps)(PocLetterModal as any);
