import {
  ITransactionLoadedAction,
  SELECT_TRANSACTION_TYPE,
  TRANSACTION_LOADED_TYPE,
  TRANSACTION_UPDATED_TYPE
} from "../actions";
import {ITransactionState} from "../states/ITransactionState";

export function transactionLoadedReducer(
  state: ITransactionState = {transaction: undefined},
  action: ITransactionLoadedAction
): ITransactionState {
  switch (action.type) {
    case TRANSACTION_LOADED_TYPE:
    case TRANSACTION_UPDATED_TYPE:
      if (action.transaction) {
        return Object.assign({}, state, {transaction: action.transaction});
      }
      break;
    case SELECT_TRANSACTION_TYPE:
      return Object.assign({}, state, {transaction: undefined});
  }
  return state;
}
