import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { css } from "../../../../styles";
import { ITransaction } from "../../../../../common/domain/ITransaction";
import { Provider } from "../../../../../common/domain/Provider";
import { OtpMode } from "../../../../../common/domain/IOtp";
import { isMobilePhoneNumber } from "../../../../../common/helpers/otpHelper";

interface ISignButtonsComponentProps {
  transaction: ITransaction,
  language: string,
  phoneNumber: string,
  signable: boolean,
  allDocumentsRead: boolean,
  isPublicSignatory: boolean,
  sign: (otpMode: OtpMode, phoneNumberIsValid?: boolean) => void,
}

export class SignButtonsComponent extends Component<ISignButtonsComponentProps, {}> {
  public enableSmsButton(): boolean {
    return this.isSignable() && !!this.props.phoneNumber && this.props.phoneNumber.toLowerCase() !== "no phone";
  }

  private enableEmailButton(): boolean {
    return this.isSignable() && (!isMobilePhoneNumber(this.props.phoneNumber) || this.props.phoneNumber.toLowerCase() === "no phone");
  }

  public isSignable() {
    return this.props.signable && this.props.allDocumentsRead;
  }

  public render(): JSX.Element {
    if (this.props.transaction && this.props.transaction.signatureProvider === Provider.LUXTRUST) {
      return (
        <div className={css("row mt-2")}>
          <div className={css("col d-inline-flex justify-content-center")}>
            <button className={css("btn btn-lg btn-success", this.isSignable() ? "" : "disabled"
            )} onClick={() => this.props.sign(OtpMode.MOBILE)} disabled={!this.isSignable()}>
              <MarkupText id={this.props.language + ".signature.sign"}>
                Send by email
              </MarkupText>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className={css("row")}>
            <div className={css("col d-flex align-items-center flex-column")}>
              <MarkupText id={this.props.language + ".signature.otp message"}>
                To be able to sign the contract please select how to receive your one time password:
              </MarkupText>
            </div>
          </div>
          <div className={css("row mt-2")}>
            <div className={css("col d-inline-flex justify-content-center")}>
              {(!this.props.isPublicSignatory)
                && <button className={css("btn btn-lg btn-success", this.isSignable() ? "" : "disabled")}
                  onClick={() => this.props.sign(OtpMode.EMAIL)} disabled={!this.enableEmailButton()}>
                  <MarkupText id={this.props.language + ".signature.email"}>
                    Send by email
                  </MarkupText>
                </button>
              }
              <button className={css("btn btn-lg btn-success ml-3", this.enableSmsButton() ? "" : "disabled")}
                onClick={() => this.props.sign(OtpMode.SMS, this.props.isPublicSignatory)} disabled={!this.enableSmsButton()}>
                <MarkupText id={this.props.language + ".signature.sms"}>
                  Send by SMS
                </MarkupText>
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}