import { IWidgetConfigurationProps, withWidgetConfiguration } from "@sgwt-widget/core";
import { Component, h } from "preact";
import { ITransaction } from "../../../common/domain/ITransaction";
import { ApplicationState } from "../../states/ApplicationState";
import { Action } from "redux";
import { connect } from "preact-redux";
import { setDocumentsValidation } from "../../actions/ISignatureAction";
import { css } from "../../styles";
import { areAllDocumentsForSignatureValidated, getDocumentConsentById, getDocumentsForSignature, isDocumentPresentInConsentArray, updateDocumentConsentArray } from "../../../common/services/SignatureWorkflow";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { IDocumentConsent } from "../../../common/domain/ISignatureWorkflow";

export interface IWorkflowDocumentListProps {
  documentsValidation: boolean;
  transaction: ITransaction;
  setDocumentsValidation: (documentsValidation: boolean) => void;
}

const WorkflowDocumentList = withWidgetConfiguration(
  class extends Component<IWorkflowDocumentListProps & IWidgetConfigurationProps> {
    public documentsConsentArray: IDocumentConsent[] = [];

    private initAllDocumentsValidated(): void {
      if (this.props.documentsValidation) {
        const documentsFoSignature = getDocumentsForSignature(this.props.transaction);
        documentsFoSignature.forEach(document => {

          const index = this.documentsConsentArray.findIndex(doc => doc.documentId === document.id);
          if(index < 0) {
            this.documentsConsentArray.push({
              documentId: document.id,
              isValidate: true,
            });
          } else {
            this.documentsConsentArray[index] = {
              documentId: document.id,
              isValidate: true,
            };
          }
        });
      }
    }

    private setDocumentsForSignatureValidate(): void {
      const documentsFoSignature = getDocumentsForSignature(this.props.transaction);
      const areAllDocumentsValidated = areAllDocumentsForSignatureValidated(this.documentsConsentArray)
      this.props.setDocumentsValidation(documentsFoSignature.length === this.documentsConsentArray.length && areAllDocumentsValidated);
    }

    private updateDocumentsConsentArray(event: any, docId: number): void {
      const isAlreadyPresent: boolean = isDocumentPresentInConsentArray(docId, this.documentsConsentArray);
      if (isAlreadyPresent) {
        updateDocumentConsentArray(docId, event.target.checked, this.documentsConsentArray);
      } else {
        this.documentsConsentArray.push({
          documentId: docId,
          isValidate: event.target.checked
        })
      }
      this.setDocumentsForSignatureValidate();
    }

    private renderDocumentCheckBox(document: ITransactionDocument): JSX.Element {
      return (
        <article>
          <div className={css("form-group form-check")}>
            <input
              type="checkbox"
              className={css("form-check-input")}
              id={"id-" + `${document.id}`}
              onChange={(e: any) => this.updateDocumentsConsentArray(e, document.id)}
              checked={getDocumentConsentById(document.id, this.documentsConsentArray)}
            />
            <label
              className={`${css("form-check-label font-weight-bold")}`}
              for={"id-" + `${document.id}`}
            >
              &nbsp;
              {document.filename}
            </label>
          </div>
        </article>
      )
    }

    public render(): JSX.Element {
      const checkboxes: JSX.Element[] = [];
      this.initAllDocumentsValidated();
      const documents = getDocumentsForSignature(this.props.transaction);
      documents.forEach(document => {
        checkboxes.push(this.renderDocumentCheckBox(document))
      })
      return (
        <section>
          <br></br>
          {checkboxes}
        </section>
      );
    }
  });

function mapStateToProps(state: ApplicationState) {
  return {
    documentsValidation: state.signatureState.documentsValidation,
    transaction: state.transactionState.transaction,
  }
};

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  setDocumentsValidation: (documentsValidation: boolean) => dispatch(setDocumentsValidation(documentsValidation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkflowDocumentList as any);