import { Action } from "redux";
import { ITransaction } from "../../common/domain/ITransaction";

export const TRANSACTION_LOADED_TYPE = "TRANSACTION_LOADED";
export const TRANSACTION_UPDATED_TYPE = "TRANSACTION_UPDATED";

export interface ITransactionLoadedAction extends Action<string> {
  transaction: ITransaction;
}

export function transactionLoaded(transaction: ITransaction): ITransactionLoadedAction {
  return {
    transaction,
    type: TRANSACTION_LOADED_TYPE,
  };
}

export function transactionUpdated(transaction: ITransaction): ITransactionLoadedAction {
  return {
    transaction,
    type: TRANSACTION_UPDATED_TYPE,
  };
}
