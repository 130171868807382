import { Component, h } from "preact";
import { ISignatureWorkflowStep } from "../../../../common/domain/ISignatureWorkflow";
import { ITransaction } from "../../../../common/domain/ITransaction";
import { getSelelectedSpiderCollateralOptionOrder } from "../../../../common/helpers/collateralHelper";
import { isConsentCheck } from "../../../../common/helpers/transactionHelper";
import { DefaultCheckbox } from "../../../components/default-checkbox/DefaultCheckbox";

export interface ICollateralCheckboxProps {
  step: number,
  consent: string,
  language: string,
  companyName: string,
  translationId: string,
  isErrorConsent: boolean,
  transaction: ITransaction,
  existingConsents: Map<number, string[]>,
  signatureWorkflowSteps: ISignatureWorkflowStep[],
  acceptConsent: (event: any) => void,
  addConsent: (elt: HTMLElement) => void,
}

export class CollateralCheckbox extends Component<ICollateralCheckboxProps> {
  
  public render(): JSX.Element {
    if (this.props.signatureWorkflowSteps) {
      const collaterals = this.props.signatureWorkflowSteps[this.props.step].collateral;
      
      if(collaterals) {
        const selectedCollateralOption = this.props.transaction.spiderCollateralOption;
        
        if(selectedCollateralOption) {
          const selectedCollateralOrder = getSelelectedSpiderCollateralOptionOrder(selectedCollateralOption);
          const selectedLabel = collaterals[selectedCollateralOrder]
          return <DefaultCheckbox
            label={selectedLabel}
            translationId={this.props.translationId}
            companyName={this.props.companyName}
            isErrorConsent={this.props.isErrorConsent}
            isCheckboxChecked={isConsentCheck(this.props.step, this.props.consent, selectedLabel, true, this.props.existingConsents)}
            isBasedOnSignatureWorkflow={true}
            acceptConsent={(event: any) => this.props.acceptConsent(event)}
            addConsent={(elt: HTMLElement) => this.props.addConsent(elt)}
          />
        }
      }
    }
    return <div></div>
  }
}
