import {Component, h} from "preact";
import {Text} from "preact-i18n";

import {ISignatureCounterpart} from "../../../../common/domain/ISignatureCounterpart";
import {css} from "../../../styles";
import {IUser} from "../../../../common/domain/User";
import { SignatoryComponent } from "./signatory-component/SignatoryComponent";
import { ContactOnlyComponent } from "./contact-only-component/ContactOnlyComponent";

interface IState {
  counterparts: ISignatureCounterpart[];
}

export interface ICounterpartsComponentProps {
  counterparts: ISignatureCounterpart[];
  language: string;
  transactionId: number;
  currentUser?: IUser;
  esignBaseUrl: string;
}

export class CounterpartsComponent extends Component<ICounterpartsComponentProps,
  IState> {

    private renderContactTitle(displaySeparator: boolean) {
      return (
        <section>
          {displaySeparator && <hr class={"mt-0"}></hr>}
          <h6 class={css("text-secondary")}>
            <Text id={this.props.language + ".counterpart.additional_contacts"}>
              Notified contacts
            </Text>
          </h6>
        </section>
      );
    }

    public render(): JSX.Element {
      const counterparts = this.props.counterparts;
      if (counterparts) {
        counterparts.sort((c1: ISignatureCounterpart, c2: ISignatureCounterpart) => c1.order - c2.order);
        const signatoriesToDisplay = counterparts
          .filter((counterpart) => counterpart.counterpartSignatories.length > 0)
          .map((counterpart: ISignatureCounterpart) => (
          <article className={css("col-12 mb-4")}>
            <SignatoryComponent
              counterpart={counterpart}
              language={this.props.language}
              currentUser={this.props.currentUser}
              transactionId={this.props.transactionId}
              esignBaseUrl={this.props.esignBaseUrl}
            />
            {counterpart.counterpartContacts.length > 0 && this.renderContactTitle(false)}
            <ContactOnlyComponent
              counterpart={counterpart}
              language={this.props.language}
            />
          </article>
        ));
        const contactsToDisplay = counterparts
          .filter((counterpart) => counterpart.counterpartSignatories.length == 0)
          .filter((counterpart) => counterpart.counterpartContacts.length > 0)
          .map((counterpart: ISignatureCounterpart) => (
          <article className={css("col-12")}>
            <ContactOnlyComponent
              counterpart={counterpart}
              language={this.props.language}
            />
          </article>
        ));
        return (
          <section>
            <section className={css("row")}>{signatoriesToDisplay}</section>
            {contactsToDisplay.length > 0 && this.renderContactTitle(true)}
            <section className={css("row")}>{contactsToDisplay}</section>
          </section>
        );
      }
      return <section className={css("row")}>&nbsp;</section>;
    }
}
