import {Component, h} from "preact";
import {ApplicationState} from "../../states/ApplicationState";
import {Action} from "redux";
import {connect} from "preact-redux";
import {sign} from "../../../common/services/SignatureService";
import {IWidgetConfigurationProps, withWidgetConfiguration} from "@sgwt-widget/core";
import {ITransaction} from "../../../common/domain/ITransaction";
import {css} from "../../styles";
import {MarkupText, Text} from "preact-i18n";
import {documentLoaded} from "../../actions/IDocumentAction";
import {cancelSignature, setChannel, transactionSigned} from "../../actions/ISignatureAction";
import {Spinner} from "../../components/spinner/Spinner";
import {openChannel} from "../../../common/services/ChannelService";
import {IOtp, OtpMode} from "../../../common/domain/IOtp";
import {IChannel} from "../../../common/domain/IChannel";
import {registerCertificate} from "../../../common/services/CertificateService";


interface ISignatureWithoutOtpModalState {
  open: boolean;
  processing: boolean;
  channel: IChannel;
  signing: boolean;
}

export interface ISignatureWithoutOtpModalProps {
  transaction: ITransaction;
  language: string;
  documentLoaded: (loaded: boolean) => void;
  transactionSigned: (isSigned: boolean) => void;
  otpMandatory: boolean;
  setChannel: (channel: IChannel) => void;
  cancelSignature: () => void;
  signing: boolean;
}

const SignatureWithoutOtpModal = withWidgetConfiguration(
  class extends Component<ISignatureWithoutOtpModalProps & IWidgetConfigurationProps,
    ISignatureWithoutOtpModalState> {
    private readonly NO_CONSENT_NEEDED = "No consent needed";

    public async componentDidMount() {
      await this.handleModalState(this.props.otpMandatory, this.props.signing);
    }

    public async componentWillReceiveProps(nextProps: ISignatureWithoutOtpModalProps) {
      await this.handleModalState(nextProps.otpMandatory, nextProps.signing);
    }

    public async handleModalState(otpMandatory: boolean, signing: boolean) {
      this.setState({
        open: !otpMandatory,
        signing
      });
    }

    public confirm() {
      this.setState({processing: true});
      openChannel(
        this.props.widgetConfiguration,
        this.props.transaction.id,
        OtpMode.NONE
      ).then((channel: IChannel) => {
        this.setState({channel});
        this.props.setChannel(channel);
      }).then(() =>
        registerCertificate(
          this.props.widgetConfiguration,
          this.props.transaction.id,
          this.state.channel,
          this.NO_CONSENT_NEEDED
        )
      ).then(() =>
        sign(
          this.props.widgetConfiguration,
          this.props.transaction.id,
          this.state.channel,
          this.props.language
        )
      )
        .then(() => {
          this.setState({
            open: false,
            processing: false,
          });
        })
        .then(() => this.props.transactionSigned(true))
        .then(() => this.props.documentLoaded(false))
        .catch(() => {
          this.props.cancelSignature();
          this.setState({processing: false});
        });
    }


    private cancel() {
      if(this.state.open){
        this.props.cancelSignature();
        this.setState({open: false});
      }
    }

    public render(): JSX.Element {
      if (this.state.open && this.state.signing) {
        return (
          <article>
            <div id={'modal'}
                 className={`${css("modal fade show")} opened`}
                 tabIndex={-1}
                 role="dialog">
              <div
                className={css("modal-dialog modal-dialog-centered")}
                role="document">
                <div className={css("modal-content shadow-max")}>
                  <div className={css("modal-body")}>
                    <h2>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.titleSuccess"}>
                        Sign contract
                      </MarkupText>
                    </h2>
                    <br></br>
                    <div>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.bodySuccess"}>
                        Are you sure you want to sign this contract?
                      </MarkupText>
                    </div>
                    <article
                      className={css(this.state.processing ? "" : "d-none")}
                    >
                      <Spinner loading={this.state.processing}/>
                    </article>
                  </div>
                  <div className={css("modal-footer")}>
                    <button type="button"
                            className={css("btn btn-xl btn-default", this.state.processing ? "disabled" : "")}
                            data-dismiss="modal"
                            onClick={() => this.cancel()}>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.cancel"}>
                        Cancel
                      </MarkupText>
                    </button>
                    <button type="button"
                            className={css("btn btn-xl btn-primary", this.state.processing ? "disabled" : "")}
                            data-dismiss="modal"
                            onClick={async () => this.confirm()}>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.sign"}>
                        Sign
                      </MarkupText>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>);
      }
      return <article/>;
    }

  });

function mapStateToProps(state: ApplicationState) {
  return {
    language: state.languageState.language,
    transaction: state.transactionState.transaction,
    otpMandatory: state.signatureState.otpMandatory,
    signing: state.signatureState.signing,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  documentLoaded: (loaded: boolean) => dispatch(documentLoaded(loaded)),
  transactionSigned: (isSigned: boolean) => dispatch(transactionSigned(isSigned)),
  setChannel: (channel: IChannel) => dispatch(setChannel(channel)),
  cancelSignature: () => dispatch(cancelSignature()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignatureWithoutOtpModal as any);
