import {WidgetConfiguration} from "@sgwt-widget/core";
import {Observable} from "rxjs";
import {ICounterpartSignatory} from "../domain/ICounterpartSignatory";
import {ISignatureCounterpart} from "../domain/ISignatureCounterpart";
import {ITransaction, ITransactionStatus} from "../domain/ITransaction";
import {ITransactionValidator} from '../domain/ITransactionValidator';
import {IUser} from "../domain/User";
import {ajaxCall} from "./AjaxService";

export async function getCurrentUser(widgetConfiguration: WidgetConfiguration): Promise<IUser> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/users/current`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export function findCurrentCounterpartAndSignatory(user: IUser,
                                     transaction: ITransaction): {counterpart:ISignatureCounterpart | undefined, signatory:ICounterpartSignatory | undefined} {

  if (!user || !transaction || !transaction.signatureCounterparts) {
    return {counterpart:undefined, signatory:undefined};
  }

  const sortedCounterparts = [...transaction.signatureCounterparts]
    .sort(
      (left: ISignatureCounterpart, right: ISignatureCounterpart) =>
        left.order - right.order
    );

  const nextCounterpart = sortedCounterparts
    .reduce((counterpart: ISignatureCounterpart | undefined, c: ISignatureCounterpart) => {
      if (!counterpart && c.counterpartSignatories.length > 0
        && c.counterpartSignatories.every((s: ICounterpartSignatory) => !s.hasSigned)) {
        return c;
      }
      return counterpart;
    }, undefined);

  const userAlreadySignedThisTransaction = nextCounterpart && nextCounterpart.counterpartSignatories
    .some(signatory => signatory.identifier == user.username && signatory.hasSigned);
  
  const nextSignatory = (nextCounterpart && !userAlreadySignedThisTransaction) ? nextCounterpart.counterpartSignatories.find((s: ICounterpartSignatory) => s.identifier === user.username): undefined;
  
  return {counterpart:nextCounterpart, signatory:nextSignatory};
}

export function isUserValidSignatory(user: IUser,
                                     transaction: ITransaction): { isSignatory: boolean; phoneNumber?: string } {
  const signatory = findCurrentCounterpartAndSignatory(user, transaction).signatory;
  if (!signatory) {
    return {isSignatory: false};
  }
  return {
    isSignatory: !!signatory,
    phoneNumber: signatory.phoneNumber,
  };
}

export function findCurrentValidator(user: IUser, transaction: ITransaction): ITransactionValidator | undefined {
  if (!user || !transaction || !transaction.signatureCounterparts) {
    return undefined;
  }
  return transaction.signatureCounterparts
    .filter((signatureCounterpart) => signatureCounterpart.order === transaction.currentCounterpartOrder)
    .filter((signatureCounterpart) => !!signatureCounterpart.counterpartValidators)
    .reduce((collector: ITransactionValidator[], {counterpartValidators}) => ([...collector, ...counterpartValidators]), [])
    .find((v: ITransactionValidator) => v.email === user.username && !v.hasValidated);
}

export function isUserValidValidator(user: IUser, transaction: ITransaction, validatorType: string): boolean {
  const validator = findCurrentValidator(user, transaction);
  return !!validator && (transaction.status === ITransactionStatus.PENDING_FOR_VALIDATION
    || transaction.status === ITransactionStatus.PENDING_FOR_INTERMEDIATE_VALIDATOR)
    && validator.type === validatorType;
}

export function isOtpMandatoryForSignatory(user: IUser,
                                           transaction: ITransaction): boolean {
  const signatory = findCurrentCounterpartAndSignatory(user, transaction).signatory;
  if (!signatory) {
    return false;
  }
  return !!signatory && signatory.signatureWithOtp;
}
