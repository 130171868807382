import { IWidgetConfigurationProps, withWidgetConfiguration } from "@sgwt-widget/core";
import { Component, h } from "preact";
import { ITransaction } from "../../../common/domain/ITransaction";
import { ApplicationState } from "../../states/ApplicationState";
import { connect } from "preact-redux";
import { getDateFormat, getSignatureWorkflowByLanguage } from "../../../common/services/SignatureWorkflow";
import { ISignatureWorkflow } from "../../../common/domain/ISignatureWorkflow";
import { OtpMode } from "../../../common/domain/IOtp";
import { IUser } from "../../../common/domain/User";

export interface IWorkflowTextProps {
  language: string;
  transaction: ITransaction;
  step: number;
  signatureWorkflow: ISignatureWorkflow;
  otpMode: OtpMode;
  user: IUser
}

const WorkflowText = withWidgetConfiguration(
  class extends Component<IWorkflowTextProps & IWidgetConfigurationProps> {

    private renderSignatureWorkflowText(text: string): JSX.Element {
      const deadline = this.props.transaction.deadline != undefined ? this.props.transaction.deadline : "";
      const date = new Date(deadline);
      const formatOptions = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      } as any;
      const dateFormat = getDateFormat(this.props.language);
      const dateString = date.toLocaleDateString(dateFormat, formatOptions);
      const creator = this.props.transaction.creator != undefined ? this.props.transaction.creator.email : "";
      const email = this.props.user.username;
      const strongMatch = text.match(/<(b|(\/b))>/g);
      const isStrong = strongMatch && strongMatch.length > 0;
      const content = text
        .replace(/<(b|(\/b))>/g, "")
        .replace(/{{date}}/g, dateString)
        .replace(/{{OTP}}/g, OtpMode[this.props.otpMode])
        .replace(/{{sender}}/g, creator)
        .replace(/{{email}}/g, email)
      return (
        <article className={"mb-2"}>
            { isStrong
              ? <strong> { content } </strong>
              : content
            }
        </article>
      )
    }

    public render(): JSX.Element {
      const translatedJson = getSignatureWorkflowByLanguage(this.props.language, this.props.signatureWorkflow.languages);
      const currentStep = translatedJson[this.props.step];
      const workflowTextArray = currentStep.text || [];
      const text: JSX.Element[] = [];
      workflowTextArray.forEach(elt => {
        text.push(this.renderSignatureWorkflowText(elt))
      })
      return <section>{text}</section>;
    }

  });

function mapStateToProps(state: ApplicationState) {
  return {
    language: state.languageState.language,
    transaction: state.transactionState.transaction,
    step: state.signatureState.step,
    signatureWorkflow: state.signatureState.signatureWorkflow,
    otpMode: state.signatureState.otpMode,
    user: state.userState.user,
  }
};

export default connect(
  mapStateToProps,
)(WorkflowText as any);