import { WidgetConfiguration } from "@sgwt-widget/core";
import { ITransaction } from "../domain/ITransaction";
import { ITransactionDocument, readOptionTypes } from "../domain/TransactionDocument";
import { buildDocumentUrl, buildPocUrl } from "../services/DocumentService";
import { ApplicationState } from "../../esg-signature-page/states/ApplicationState";
import { Origin } from "../domain/Origin";
import { Provider } from "../domain/Provider";

export async function selectDocument(
  document: ITransactionDocument, 
  pocId: number, 
  isPocSigned: boolean, 
  transaction: ITransaction,
  widgetConfiguration: WidgetConfiguration,
  readDocument: (documentId: number, documentUrl: string) => void,
  readDocumentCompletion: (documentId: number, completion: number) => void,
) {
  const fullRead = 100;
  const transactionId: number = isPocSigned ? transaction.id : pocId;
  if (isPocSigned || transactionId) {
    readDocument(
      document.id,
      await buildDocumentUrl(
        widgetConfiguration,
        transactionId,
        document.id
      )
    );
    if(document.readOption === readOptionTypes.VIEW) {
      readDocumentCompletion(document.id, fullRead)
    }
  } else {
    readDocument(
      document.id,
      await buildPocUrl(
        widgetConfiguration,
        document.id
      )
    )
  }
}

export function isMigratedDocusignDocument(state: ApplicationState): boolean {
  return state.transactionState.transaction
    ? state.transactionState.transaction.origin === Origin.ISIGN &&
        state.transactionState.transaction.signatureProvider ===
          Provider.DOCUSIGN
    : false;
}
