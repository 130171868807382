import { ICounterpartContact } from "./ICounterpartContact";
import { ICounterpartSignatory } from "./ICounterpartSignatory";
import { ITransactionValidator } from './ITransactionValidator';

export interface ISignatureCounterpart {
  id: number;
  icClientId: string;
  counterpartContacts: ICounterpartContact[];
  order: number;
  counterpartSignatories: ICounterpartSignatory[];
  type: CounterpartType;
  counterpartValidators: ITransactionValidator[];
  name?: string; // used by Transfer to Signatories
}

export enum CounterpartType {
  EXTERNAL = "EXTERNAL",
  INTERNAL = "INTERNAL",
  CORPORATE = "CORPORATE",
  LAWYER = "LAWYER",
  LOCAL_SIGNER = "LOCAL_SIGNER",
  SIGNATORY = "SIGNATORY",
}