import {Component, h} from "preact";
import {ApplicationState} from "../../states/ApplicationState";
import {Action} from "redux";
import {connect} from "preact-redux";
import {validateCorporateSignature, rejectCorporateSignature} from "../../../common/services/SignatureService";
import {IWidgetConfigurationProps, withWidgetConfiguration} from "@sgwt-widget/core";
import {ITransaction} from "../../../common/domain/ITransaction";
import {css} from "../../styles";
import {MarkupText, Text} from "preact-i18n";
import {documentLoaded} from "../../actions/IDocumentAction";
import {allDocumentsRead, transactionSigned} from "../../actions/ISignatureAction";
import {Spinner} from "../../components/spinner/Spinner";
import {confirmValidation, transactionLoaded, transactionUpdated} from "../../actions";

interface ICorporateSignatureModalState {
  open: boolean;
  processing: boolean;
}

export interface ICorporateSignatureModalProps {
  isSignatureValidated: boolean;
  openValidationModal: boolean;
  transaction: ITransaction;
  language: string;
  documentLoaded: (loaded: boolean) => void;
  transactionSigned: (isSigned: boolean, transaction: ITransaction) => void;
  allDocumentsRead: (areRead: boolean) => void;
  confirmValidation: (open: boolean, isConfirmed: boolean) => void;
}

const CorporateSignatureModal = withWidgetConfiguration(
  class extends Component<ICorporateSignatureModalProps & IWidgetConfigurationProps,
    ICorporateSignatureModalState> {

    public async componentDidMount() {
      await this.handleModalState(this.props.openValidationModal);
    }

    public async componentWillReceiveProps(nextProps: ICorporateSignatureModalProps) {
      await this.handleModalState(nextProps.openValidationModal);
    }

    public async handleModalState(openValidationModal: boolean) {
      this.setState({open: openValidationModal});
    }

    public confirm() {
      this.setState({processing: true});
      if (this.props.isSignatureValidated) {
        validateCorporateSignature(this.props.widgetConfiguration, this.props.transaction, this.props.language)
          .then((transaction: ITransaction) => {
            this.setState({
              open: false,
              processing: false,
            });
            return transaction
          })
          .then((transaction: ITransaction) => this.props.transactionSigned(true, transaction))
          .then(() => this.props.confirmValidation(false, false))
          .then(() => this.props.documentLoaded(false))
          .catch(() => this.setState({processing: false}));

      } else {
        rejectCorporateSignature(this.props.widgetConfiguration, this.props.transaction, this.props.language)
          .then((transaction: ITransaction) => {
            this.setState({
              open: false,
              processing: false,
            });
            return transaction;
          })
          .then((transaction: ITransaction) => this.props.transactionSigned(true, transaction))
          .then(() => this.props.documentLoaded(false))
          .catch(() => this.setState({processing: false}));
      }
    }


    private cancel() {
      this.setState({open: false});
      this.props.confirmValidation(false, false);
    }

    public render(): JSX.Element {
      if (this.state.open && this.props.isSignatureValidated) {
        return (
          <article>
            <div id={'modal'}
                 className={`${css("modal fade show")} opened`}
                 tabIndex={-1}
                 role="dialog">
              <div
                className={css("modal-dialog modal-dialog-centered")}
                role="document">
                <div className={css("modal-content shadow-max")}>
                  <div className={css("modal-header")}>
                    <h3 className={css("modal-title")}>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.titleSuccess"}>
                        Sign contract
                      </MarkupText>
                    </h3>
                  </div>
                  <div className={`${css("modal-body pb-0")} overflow-hidden`}>
                    <div>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.bodySuccess"}>
                        Are you sure you want to sign this contract?
                      </MarkupText>
                    </div>
                    <article
                      className={css(this.state.processing ? "" : "d-none")}
                    >
                      <Spinner loading={this.state.processing}/>
                    </article>
                  </div>
                  <div className={css("modal-footer")}>
                    <button type="button"
                            className={css("btn btn-xl btn-primary", this.state.processing ? "disabled" : "")}
                            data-dismiss="modal"
                            onClick={async () => this.confirm()}>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.sign"}>
                        Sign
                      </MarkupText>
                    </button>
                    <button type="button"
                            className={css("btn btn-xl btn-flat-secondary", this.state.processing ? "disabled" : "")}
                            data-dismiss="modal"
                            onClick={() => this.cancel()}>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.cancel"}>
                        Cancel
                      </MarkupText>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>);
      } else if (this.state.open && !this.props.isSignatureValidated) {
        return (
          <article>
            <div id={'modal'}
                 className={`${css("modal fade show")} opened`}
                 tabIndex={-1}
                 role="dialog">
              <div
                className={css("modal-dialog modal-dialog-centered")}
                role="document">
                <div className={css("modal-content shadow-max")}>
                  <div className={css("modal-header")}>
                    <h3 className={css("modal-title")}>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.titleReject"}>
                        Reject contract
                      </MarkupText>
                    </h3>
                  </div>
                  <div className={`${css("modal-body pb-0")} overflow-hidden`}>
                    <div>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.bodyReject"}>
                        This contract will return to draft status when rejecting it. Are you sure you want to reject
                        this contract?
                      </MarkupText>
                    </div>
                  </div>
                  <div className={css("modal-footer")}>
                    <button type="button"
                            className={css("btn btn-xl btn-primary")}
                            data-dismiss="modal"
                            onClick={async () => this.confirm()}>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.reject"}>
                        Reject
                      </MarkupText>
                    </button>
                    <button type="button"
                            className={css("btn btn-xl btn-flat-secondary")}
                            data-dismiss="modal"
                            onClick={() => this.cancel()}>
                      <MarkupText id={this.props.language + ".corporate-signature-modal.cancel"}>
                        Cancel
                      </MarkupText>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>);
      }
      return <article/>;
    }

  });

function mapStateToProps(state: ApplicationState) {
  return {
    language: state.languageState.language,
    isSignatureValidated: state.validatorState.isSignatureValidated,
    openValidationModal: state.validatorState.openValidationModal,
    transaction: state.transactionState.transaction
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  allDocumentsRead: (areRead: boolean) => dispatch(allDocumentsRead(areRead)),
  confirmValidation: (open: boolean, isConfirmed: boolean) => dispatch(confirmValidation(open, isConfirmed)),
  documentLoaded: (loaded: boolean) => dispatch(documentLoaded(loaded)),
  transactionSigned: (isSigned: boolean, transaction: ITransaction) => {
    dispatch(transactionSigned(isSigned));
    dispatch(transactionUpdated(transaction));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CorporateSignatureModal as any);
