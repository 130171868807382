import {
  h,
  initializeWidgetConfiguration,
  SgWidget,
  WidgetConfigurationProvider,
  WithCss,
} from "@sgwt-widget/core";
import {Provider} from "preact-redux";
import {ComponentProps, emit, props} from "skatejs";

import store from "./store";

import {focusOnDocument, loadProofOfConsent, selectTransaction} from "./actions";
import App from "./containers/app/App";

import * as stylesheet from "./esg-signature-page.scss";
import {resetOnError, resetOnLoad, resetOnLoaded, resetOnUpdate} from "./actions/IEventAction";
import {ITransaction} from "../common/domain/ITransaction";
import {IEvent} from "../common/domain/IEvent";

interface IEsgSignaturePageProps {
  transaction: number;
  documentToFocus?: string;
  proofOfConsentOnly?: boolean;
}

export class EsgSignaturePage extends SgWidget<IEsgSignaturePageProps> {
  public static is = "esg-signature-page";

  public static props: ComponentProps<IEsgSignaturePageProps> = {
    transaction: props.number,
    documentToFocus: props.string,
    proofOfConsentOnly: props.boolean,
  };

  public connected(): void {
    store.subscribe(() => {
      if (store.getState().eventState.onLoad) {
        this.dispatchLoadEvent();
      }
      if (store.getState().eventState.onLoaded) {
        this.dispatchLoadedEvent(store.getState().transactionState.transaction);
      }
      if (store.getState().eventState.onError) {
        this.dispatchErrorEvent(store.getState().eventState.onError);
      }
      if (store.getState().eventState.onUpdate) {
        this.dispatchUpdateEvent(store.getState().eventState.onUpdate!.data as ITransaction);
      }
    });
  }

  public render(): JSX.Element {
    if (!this.props.proofOfConsentOnly) {
      store.dispatch(selectTransaction(this.props.transaction));
      if (this.props.documentToFocus) {
        store.dispatch(focusOnDocument(this.props.documentToFocus));
      }
    } else {
      store.dispatch(loadProofOfConsent(true));
    }
    return (
      <WidgetConfigurationProvider
        widgetConfiguration={this.widgetConfiguration}
      >
        <WithCss styles={stylesheet.toString()}>
          <section className={"esg-signature-page"}>
            <Provider store={store}>
              <App/>
            </Provider>
          </section>
        </WithCss>
      </WidgetConfigurationProvider>
    );
  }

  public dispatchLoadEvent() {
    const detail = {
      transaction: this.props.transaction
    };
    emit(this, 'esign.signature-page.onload', {detail});
    this.widgetConfiguration.bus.publish('esign.signature-page.onload', detail);
    store.dispatch(resetOnLoad())
  }

  public dispatchLoadedEvent(transaction: ITransaction | undefined) {
    const detail = {
      transaction: this.props.transaction,
      status: transaction ? transaction.status : undefined
    };
    emit(this, 'esign.signature-page.onloaded', {detail});
    this.widgetConfiguration.bus.publish('esign.signature-page.onloaded', detail);
    store.dispatch(resetOnLoaded())
  }

  public dispatchErrorEvent(errorEvent?: IEvent) {
    const detail = errorEvent ? errorEvent.data : undefined;
    emit(this, 'esign.signature-page.onerror', {detail});
    this.widgetConfiguration.bus.publish('esign.signature-page.onerror', detail);
    store.dispatch(resetOnError())
  }

  public dispatchUpdateEvent(transaction: ITransaction | undefined) {
    const detail = {
      transaction: transaction ? transaction.id : this.props.transaction,
      status: transaction ? transaction.status : undefined
    };
    emit(this, 'esign.signature-page.onupdate', {detail});
    this.widgetConfiguration.bus.publish('esign.signature-page.onupdate', detail);
    store.dispatch(resetOnUpdate())
  }
}

initializeWidgetConfiguration(EsgSignaturePage, {
  neverUseShadowDOM: true,
});
customElements.define(EsgSignaturePage.is, EsgSignaturePage);
