import {Action} from "redux";

export const POC_IS_SIGNED_TYPE = "POC_IS_SIGNED";
export const POC_IS_CREATED_TYPE = "POC_IS_CREATED";
export const INITIALIZE_POC_TYPE = "INITIALIZE_POC";
export const DISPLAY_POC_LETTER_TYPE = "DISPLAY_POC_LETTER";
export const LOAD_PROOF_OF_CONSENT_TYPE = "LOAD_PROOF_OF_CONSENT";

export interface IPocAction extends Action<string> {
  pocId?: number;
  isPocSigned?: boolean;
  displayPocLetter?: boolean;
  forceDisplay?: boolean;
}

export function pocSigned(isPocSigned: boolean, pocId?: number): IPocAction {
  return {
    isPocSigned,
    pocId,
    type: POC_IS_SIGNED_TYPE,
  };
}

export function pocIsCreated(pocId: number): IPocAction {
  return {
    pocId,
    type: POC_IS_CREATED_TYPE,
  };
}

export function initializeNonExistingPoc(): IPocAction{
  return {
    type: INITIALIZE_POC_TYPE
  };
}

export function canDisplayPocLetterModal(isLetterModalDisplayed: boolean): IPocAction{
  return {
    displayPocLetter: isLetterModalDisplayed,
    type: DISPLAY_POC_LETTER_TYPE
  };
}

export function loadProofOfConsent(withPocLetter: boolean): IPocAction {
  return {
    displayPocLetter: withPocLetter,
    forceDisplay: true,
    type: LOAD_PROOF_OF_CONSENT_TYPE
  };
}
