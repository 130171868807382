import { ISignatureCounterpart } from "./ISignatureCounterpart";
import { ITransactionAnnex } from "./ITransactionAnnex";
import { ITransactionDocument } from "./TransactionDocument";
import { ITransactionValidator } from "./ITransactionValidator";
import { IContractType } from "./IContractType";
import { Provider } from "./Provider";
import { ICreator } from "./ICreator";
import { Origin } from "./Origin";

export interface ITransaction {
  id: number;
  signatureCounterparts: ISignatureCounterpart[];
  documentsForSignature: ITransactionDocument[];
  documentsForInformation: ITransactionAnnex[];
  status: ITransactionStatus;
  isPoc?: boolean;
  validators?: ITransactionValidator[];
  contractType?: IContractType;
  signatureProvider: Provider;
  transactionId?: string;
  deadline?: string;
  currentCounterpartOrder?: number;
  contractName?: string;
  signatureLanguage: string;
  creator?: ICreator;
  origin?: Origin;
  spiderCollateralOption?: ITransactionCollateralOptions;
}

export enum ITransactionStatus {
  DRAFT = "DRAFT",
  PENDING_FOR_VALIDATION = 'PENDING_FOR_VALIDATION',
  PENDING_FOR_INTERNAL_SIGNATORY = 'PENDING_FOR_INTERNAL_SIGNATORY',
  PENDING_FOR_INTERMEDIATE_VALIDATOR = "PENDING_FOR_INTERMEDIATE_VALIDATOR",
  PENDING_FOR_EXTERNAL_SIGNATORY = "PENDING_FOR_EXTERNAL_SIGNATORY",
  DENIED_BY_INTERNAL_SIGNATORY = "DENIED_BY_INTERNAL_SIGNATORY",
  DENIED_BY_EXTERNAL_SIGNATORY = "DENIED_BY_EXTERNAL_SIGNATORY",
  COMPLETED = "COMPLETED",
  COMPLETED_PROVIDER_STATUS_NOT_FOUND = 'COMPLETED_PROVIDER_STATUS_NOT_FOUND',
  COMPLETED_BY_ADMIN = "COMPLETED_BY_ADMIN",
  ABORTED_BY_INTERNAL_SIGNATORY = 'ABORTED_BY_INTERNAL_SIGNATORY',
  ABORTED_BY_EXTERNAL_SIGNATORY = 'ABORTED_BY_EXTERNAL_SIGNATORY',
  CANCELLED_BY_MIDDLE_OFFICE = "CANCELLED_BY_MIDDLE_OFFICE",
  CANCELLED_BY_ADMIN = "CANCELLED_BY_ADMIN",
  ARCHIVED = 'ARCHIVED',
  CHASE = 'CHASE',
  POC = 'POC',
  EXPIRED = 'EXPIRED'
}

export enum ITransactionCollateralOptions {
  COLLATERAL = "COLLATERAL",
  PROMISE_OF_COLLATERAL = "PROMISE_OF_COLLATERAL"
}