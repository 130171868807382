import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { css } from "../../../../styles";

interface ICancelSignatureComponentProps {
  language: string,
  allDocumentsRead: boolean,
  reject: () => void,
}

export class CancelSignatureComponent extends Component<ICancelSignatureComponentProps, {}> {
  public render(): JSX.Element {
    return (
      <div className={css("row mt-2 mb-3")}>
        <div className={css("col d-flex align-items-center flex-column")}>
          <a className={`pointer ${css(this.props.allDocumentsRead ? "" : "disabled")}`}
            onClick={() => this.props.reject()}>
            <MarkupText id={this.props.language + ".signature.reject"}>
              Click this link to cancel the transaction
            </MarkupText>
          </a>
        </div>
      </div>
    )
  }
}