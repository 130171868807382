import {WidgetConfiguration} from "@sgwt-widget/core";
import {ICounterpartSignatory} from "../domain/ICounterpartSignatory";
import {ajaxCall} from "./AjaxService";

const MOBILE = 'Mobile';

export async function getMyInformation(widgetConfiguration: WidgetConfiguration): Promise<ICounterpartSignatory> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "GET",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/v2/contacts/me`,
  }, widgetConfiguration)
    .map(response => response.response)
    .map((icContact: any) => {
      return {
        identifier: icContact.email ? icContact.email : '',
        civility: icContact.civility ? icContact.civility : '',
        firstname: icContact.firstname,
        lastname: icContact.lastname,
        country: icContact.mainAddressCountry ? icContact.mainAddressCountry : '',
        icContactId: icContact.contactReferentialId,
        jobTitle: icContact.jobTitle ? icContact.jobTitle : '',
        organization: icContact.clientName ? icContact.clientName : '',
        phoneNumber: icContact.mobilePhone ? icContact.mobilePhone : icContact.mainPhone,
      } as ICounterpartSignatory
    })
    .toPromise();
}
