import {
  IWidgetConfigurationProps,
  withWidgetConfiguration,
} from "@sgwt-widget/core";
import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";
import { connect } from "preact-redux";
import { Action } from "redux";
import { IChannel } from "../../../common/domain/IChannel";
import { IOtp, OtpMode } from "../../../common/domain/IOtp";
import { ITransactionAnnex } from "../../../common/domain/ITransactionAnnex";
import { registerCertificate } from "../../../common/services/CertificateService";
import { openChannel } from "../../../common/services/ChannelService";
import { requestOtp, submitOtp } from "../../../common/services/OtpService";
import { sign } from "../../../common/services/SignatureService";
import { documentLoaded, loadDocuments, readDocument } from "../../actions/IDocumentAction";
import {
  acceptConsent,
  allDocumentsRead,
  checkPhoneNumber,
  setChannel,
  setOtp,
  transactionSigned,
} from "../../actions/ISignatureAction";
import { Spinner } from "../../components/spinner/Spinner";
import { ApplicationState } from "../../states/ApplicationState";
import { css } from "../../styles";
import { ITransaction } from "../../../common/domain/ITransaction";
import { ITransactionDocument } from "../../../common/domain/TransactionDocument";
import { currentUserIsSignatory, setCurrentUserPhoneNumber } from "../../actions/IUserAction";
import { IUser } from "../../../common/domain/User";
import { pocIsCreated, pocSigned } from "../../actions/IPocAction";
import { initProofOfConsent } from "../../../common/services/ProofOfConsentService";
import { IProofOfConsent } from "../../../common/domain/IProofOfConsent";
import { transactionUpdated } from "../../actions";
import { isUserValidSignatory } from "../../../common/services/UserService";

interface IOtpModalState {
  channel: IChannel;
  open: boolean;
  otp: IOtp;
  processing: boolean;
  transaction: ITransaction;
  documentsCount: number;
  proofOfConsent: IProofOfConsent;
  isOtpValid: boolean;
}

export interface IOtpModalProps {
  transactionId: number;
  signatureProcessing: boolean;
  language: string;
  otpMode: OtpMode;
  consent: string;
  phoneNumberIsValid: boolean;
  documentLoaded: (loaded: boolean) => void;
  setOtp: (otp: IOtp) => void;
  setChannel: (channel: IChannel) => void;
  transactionSigned: (isSigned: boolean, transaction: ITransaction) => void;
  checkPhoneNumber: () => void;
  pocId: number;
  isPocSigned: boolean;
  forcePocDisplay: boolean;
  loadDocuments: (documents: ITransactionDocument[]) => void;
  currentUserIsSignatory: (valid: boolean) => void;
  setCurrentUserPhoneNumber: (phoneNumber?: string) => void;
  user: IUser;
  pocSigned: (sign: boolean, pocId: number) => void;
  acceptConsent: (consent: string | null) => void;
  allDocumentsRead: (areRead: boolean) => void;
  pocIsCreated: (id: number) => void;
  readDocument: (documentId: number, documentUrl: string) => void;
  isValidSignatory: boolean;
}

const OtpModal = withWidgetConfiguration(
  class extends Component<IOtpModalProps & IWidgetConfigurationProps,
    IOtpModalState> {
    public otpInput: HTMLInputElement;

    public async componentDidMount() {
      this.handleSignatureState(this.props);
    }

    public async componentWillReceiveProps(nextProps: IOtpModalProps) {
      this.handleSignatureState(nextProps);
    }

    public async handleSignatureState(props: IOtpModalProps) {
      if (!this.state.open && props.signatureProcessing) {
        if (
          OtpMode.EMAIL === props.otpMode ||
          (OtpMode.SMS === props.otpMode && props.phoneNumberIsValid)
        ) {
          this.setState({ open: true, processing: true });
          if (!this.props.isPocSigned) {
            this.setState({
              proofOfConsent: await initProofOfConsent(this.props.widgetConfiguration, this.props.language.toUpperCase(), props.transactionId)
            });
            this.props.pocIsCreated(this.state.proofOfConsent.id);
          }
          const transactionId: number = this.props.isPocSigned ? this.props.transactionId : this.state.proofOfConsent.id;
          openChannel(
            this.props.widgetConfiguration,
            transactionId,
            props.otpMode
          )
            .then((channel: IChannel) => {
              this.setState({ channel });
              this.props.setChannel(channel);
              return requestOtp(
                this.props.widgetConfiguration,
                transactionId,
                channel,
                props.otpMode,
                props.language
              );
            })
            .then((otp: IOtp) => {
              this.setState({ otp });
              this.props.setOtp(otp);
              this.setState({ processing: false });
            });
          this.setState({ open: props.signatureProcessing });
        } else if (OtpMode.SMS === props.otpMode) {
          this.props.checkPhoneNumber();
        }
      }
    }

    public async componentDidUpdate() {
      if (this.state.open) {
        this.otpInput.focus();
      }
    }

    public submit(e: Event) {
      e.preventDefault();
      this.setState({
        processing: true,
        isOtpValid: true,
        otp: {
          channel: this.state.otp.channel,
          otp: this.otpInput.value
        }
      });
      this.props.setOtp(this.state.otp);
      const transactionId: number = this.props.isPocSigned ? this.props.transactionId : this.state.proofOfConsent.id;

      submitOtp(
        this.props.widgetConfiguration,
        transactionId,
        this.state.otp,
        this.state.channel
      )
        .then(() =>
          registerCertificate(
            this.props.widgetConfiguration,
            transactionId,
            this.state.channel,
            this.props.consent
          )
        )
        .then(() =>
          sign(
            this.props.widgetConfiguration,
            transactionId,
            this.state.channel,
            this.props.language
          )
        )
        .then((transaction: ITransaction) => {
          this.setState({
            open: false,
            processing: false,
            transaction
          });
          const { isSignatory, phoneNumber } = isUserValidSignatory(this.props.user, transaction);
          this.props.currentUserIsSignatory(isSignatory);
          this.props.setCurrentUserPhoneNumber(phoneNumber);
        })
        .then(() => {
          if (this.props.isValidSignatory) {
            this.props.acceptConsent(null);
            this.props.allDocumentsRead(false);
          }
          return this.props.transactionSigned(!this.props.isValidSignatory, this.state.transaction);
        })
        .then(() => {
          if (!this.props.isPocSigned && !this.props.forcePocDisplay) {
            return this.props.pocSigned(true, this.state.proofOfConsent.id);
          } else {
            return this.props.documentLoaded(false);
          }
        })
        .catch(() =>
          this.setState({
            processing: false,
            isOtpValid: false
          })
        );
    }

    public displayErrorMessage(): JSX.Element {
      if (this.state.isOtpValid === false) {
        return (
          <MarkupText id={this.props.language + ".otp-modal.otp is wrong"}>
            One time password
          </MarkupText>
        );
      }
      return <div />;
    }

    public render(): JSX.Element {
      if (this.state.open) {
        return (
          <article>
            <div id={'modal'}
              className={`${css("modal fade show")} opened`}
              tabIndex={-1}
              role="dialog"
            >
              <div
                className={css("modal-dialog modal-dialog-centered")}
                role="document"
              >
                <div className={css("modal-content shadow-max")}>
                  <div className={css("modal-header")}>
                    <h3 className={css("modal-title")}>
                      <MarkupText id={this.props.language + ".otp-modal.title"}>
                        One time password
                      </MarkupText>
                    </h3>
                  </div>
                  <div className={`${css("modal-body pb-0")} overflow-hidden`}>
                    <article
                      className={css(this.state.processing ? "" : "d-none")}
                    >
                      <Spinner loading={this.state.processing} />
                    </article>
                    <article
                      className={css(this.state.processing ? "d-none" : "")}
                    >
                      <MarkupText
                        id={this.props.language + ".otp-modal.message"}
                      >
                        Please enter the One Time Password (OTP) you received on
                        your phone or email:
                      </MarkupText>
                      <form onSubmit={(e: Event) => this.submit(e)}>
                        <div className={css("form-group")}>
                          <input
                            type="text"
                            className={css(
                              "form-control form-control-lg w-50",
                              this.state.processing ? "disabled" : ""
                            )}
                            ref={(input: HTMLInputElement) =>
                              (this.otpInput = input)
                            }
                            disabled={this.state.processing}
                          />
                        </div>
                      </form>
                      {this.displayErrorMessage()}
                    </article>
                  </div>
                  <div className={css("modal-footer")}>
                    <button
                      type="button"
                      className={css(
                        "btn btn-xl btn-primary",
                        this.state.processing ? "disabled" : ""
                      )}
                      data-dismiss="modal"
                      onClick={(e: Event) => this.submit(e)}
                      disabled={this.state.processing}
                    >
                      <MarkupText
                        id={this.props.language + ".otp-modal.submit"}
                      >
                        Submit
                      </MarkupText>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>
        );
      }
      return <article />;
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    consent: state.signatureState.consent,
    language: state.languageState.language,
    otpMode: state.signatureState.otpMode,
    phoneNumberIsValid: state.signatureState.phoneNumberIsValid,
    signatureProcessing: state.signatureState.processing,
    transactionId: state.transactionIdState.transactionId,
    pocId: state.pocState.pocId,
    isPocSigned: state.pocState.isPocSigned,
    forcePocDisplay: state.pocState.forceDisplay,
    user: state.userState.user,
    allDocumentsRead: state.signatureState.allDocumentsRead,
    isValidSignatory: state.userState.isValidSignatory
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  checkPhoneNumber: () => dispatch(checkPhoneNumber()),
  documentLoaded: (loaded: boolean) => dispatch(documentLoaded(loaded)),
  setChannel: (channel: IChannel) => dispatch(setChannel(channel)),
  setOtp: (otp: IOtp) => dispatch(setOtp(otp)),
  transactionSigned: (isSigned: boolean, transaction: ITransaction) => {
    dispatch(transactionSigned(isSigned));
    dispatch(transactionUpdated(transaction));
  },
  loadDocuments: (documents: ITransactionDocument[], documentAnnexes: ITransactionAnnex[]) =>
    dispatch(loadDocuments(documents, documentAnnexes)),
  currentUserIsSignatory: (valid: boolean) => dispatch(currentUserIsSignatory(valid)),
  setCurrentUserPhoneNumber: (phone?: string) => dispatch(setCurrentUserPhoneNumber(phone)),
  pocSigned: (isPocSigned: boolean, pocId: number) => dispatch(pocSigned(isPocSigned, pocId)),
  acceptConsent: (consent: string) => dispatch(acceptConsent(consent)),
  allDocumentsRead: (areRead: boolean) => dispatch(allDocumentsRead(areRead)),
  pocIsCreated: (pocId: number) => dispatch(pocIsCreated(pocId)),
  readDocument: (documentId: number, documentUrl: string) =>
    dispatch(readDocument(documentId, documentUrl)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtpModal as any);
