import { IWidgetConfigurationProps, withWidgetConfiguration, } from '@sgwt-widget/core';
import { Component, h } from 'preact';
import { MarkupText, Text } from 'preact-i18n';
import { connect } from 'preact-redux';
import { Action } from 'redux';
import { ITransaction } from '../../../common/domain/ITransaction';
import { rejectValidation } from '../../../common/services/IntermediateValidatorService';
import { reject } from '../../../common/services/SignatureService';
import { transactionUpdated } from '../../actions';
import { cancelContractRejection, confirmContractRejection } from '../../actions/IIntermediateValidatorAction';
import { cancelRejection, confirmRejection, } from '../../actions/IRejectionAction';
import { Spinner } from '../../components/spinner/Spinner';
import { ApplicationState } from '../../states/ApplicationState';
import { css } from '../../styles';

interface IConfirmRejectionModalState {
  open: boolean;
  processing: boolean;
}

export interface IConfirmRejectionModalProps {
  rejecting: boolean;
  rejectingByIntermediateValidator: boolean;
  language: string;
  transactionId: number;
  isValidIntermediateValidator: boolean;
  cancelRejectionByIntermediateValidator: () => void;
  cancelRejection: () => void;
  confirmRejectionByIntermediateValidator: (transaction: ITransaction) => void;
  confirmRejection: (transaction: ITransaction) => void;
}

const ConfirmRejectionModal = withWidgetConfiguration(
  class extends Component<IConfirmRejectionModalProps & IWidgetConfigurationProps,
    IConfirmRejectionModalState> {
    public async componentDidMount() {
      this.handleModalState(this.props.isValidIntermediateValidator
        ? this.props.rejectingByIntermediateValidator
        : this.props.rejecting);
    }

    public async componentWillReceiveProps(
      nextProps: IConfirmRejectionModalProps
    ) {
      this.handleModalState(nextProps.isValidIntermediateValidator
        ? nextProps.rejectingByIntermediateValidator
        : nextProps.rejecting);
    }

    public async handleModalState(rejecting: boolean) {
      this.setState({ open: rejecting });
    }

    public confirm() {
      this.setState({ processing: true });

      if (this.props.isValidIntermediateValidator) {
        rejectValidation(
          this.props.widgetConfiguration,
          this.props.transactionId
        ).then((transaction: ITransaction) => {
          this.props.confirmRejectionByIntermediateValidator(transaction);
          this.setState({
            open: false,
            processing: false,
          });
        }).catch(() => this.setState({ processing: false }));
      } else {
        reject(
          this.props.widgetConfiguration,
          this.props.transactionId,
          this.props.language
        ).then((transaction: ITransaction) => {
          this.props.confirmRejection(transaction);
          this.setState({
            open: false,
            processing: false,
          });
        }).catch(() => this.setState({ processing: false }));
      }
    }

    public cancel() {
      this.props.isValidIntermediateValidator
        ? this.props.cancelRejectionByIntermediateValidator()
        : this.props.cancelRejection();
      this.setState({ open: false });
    }

    public render(): JSX.Element {
      if (this.state.open) {
        return (
          <article>
            <div id={'modal'}
                 className={`${css('modal fade show')} opened`}
                 tabIndex={-1}
                 role="dialog"
            >
              <div
                className={css('modal-dialog modal-dialog-centered')}
                role="document"
              >
                <div className={css('modal-content shadow-max')}>
                  <div className={css('modal-header')}>
                    <h3 className={css('modal-title')}>
                      <MarkupText
                        id={
                          this.props.language + '.confirm-rejection-modal.title'
                        }
                      >
                        Cancel transaction
                      </MarkupText>
                    </h3>
                  </div>
                  <div className={css('modal-body')}>
                    <article
                      className={css(this.state.processing ? '' : 'd-none')}
                    >
                      <Spinner loading={this.state.processing}/>
                    </article>
                    <article
                      className={css(this.state.processing ? 'd-none' : '')}
                    >
                      <MarkupText
                        id={
                          this.props.language + (this.props.isValidIntermediateValidator ?
                            '.confirm-rejection-modal.message for validator' : '.confirm-rejection-modal.message')
                        }
                      >
                        Please confirm you refuse to sign this contract. This
                        action cannot be undone.
                      </MarkupText>
                    </article>
                  </div>
                  <div className={css('modal-footer')}>
                    <button
                      type="button"
                      className={css('btn btn-xl btn-default')}
                      data-dismiss="modal"
                      disabled={this.state.processing}
                      onClick={() => this.cancel()}
                    >
                      <MarkupText
                        id={
                          this.props.language +
                          '.confirm-rejection-modal.cancel'
                        }
                      >
                        Cancel
                      </MarkupText>
                    </button>
                    <button
                      type="button"
                      className={css('btn btn-xl btn-primary')}
                      data-dismiss="modal"
                      disabled={this.state.processing}
                      onClick={() => this.confirm()}
                    >
                      <MarkupText
                        id={
                          this.props.language +
                          '.confirm-rejection-modal.confirm'
                        }
                      >
                        Confirm
                      </MarkupText>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </article>
        );
      }
      return <article/>;
    }
  }
);

function mapStateToProps(state: ApplicationState) {
  return {
    language: state.languageState.language,
    rejecting: state.rejectionState.rejecting,
    rejectingByIntermediateValidator: state.intermediateValidatorState.rejecting,
    transactionId: state.transactionIdState.transactionId,
    isValidIntermediateValidator: state.userState.isValidIntermediateValidator,
  };
}

const mapDispatchToProps = (dispatch: (action: Action) => void) => ({
  cancelRejection: () => dispatch(cancelRejection()),
  cancelRejectionByIntermediateValidator: () => dispatch(cancelContractRejection()),
  confirmRejection: (transaction: ITransaction) => {
    dispatch(confirmRejection());
    dispatch(transactionUpdated(transaction));
  },
  confirmRejectionByIntermediateValidator: (transaction: ITransaction) => {
    dispatch(confirmContractRejection());
    dispatch(transactionUpdated(transaction));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmRejectionModal as any);
