import { Component, h } from "preact";
import { MarkupText } from "preact-i18n";

interface IAlertProps {
  canDisplayAlert: boolean;
  alertMessage: {
    isSuccess: boolean,
    messageId: string,
  };
}

export class Alert extends Component<IAlertProps, {}> {

  private getAlertColor(): string {
    return this.props.alertMessage.isSuccess
      ? "success"
      : "danger"
  }

  private getAlertIcon(): JSX.Element {
    return this.props.alertMessage.isSuccess
      ? <em class="material-icons md-23 vertical-align-middle">check_circle</em>
      : <em class="material-icons md-23 vertical-align-middle">cancel</em>
  }

  private getMessage(): JSX.Element {
    return <MarkupText id={this.props.alertMessage.messageId}>
      One time password
    </MarkupText>
  }

  public render(): JSX.Element {
    if (this.props.canDisplayAlert) {
      return (
        <div class="position-fixed" style="top: 80px; right: 0; z-index: 11">
          <div class={`alert alert-${this.getAlertColor()} alert-dismissible fade show d-flex align-items-center`} role="alert">
            {this.getAlertIcon()}
            &nbsp;
            {this.getMessage()}
            <button type="button" class="close" style="padding-top: 7px" data-dismiss="alert" aria-label="Close">
              <em class="material-icons md-23">close</em>
            </button>
          </div>
        </div>
      );
    } else {
      return <div />
    }
  }
}