import { IChannel } from "./IChannel";

export enum OtpMode {
  NONE,
  EMAIL,
  SMS,
  MOBILE,
}

export interface IOtp {
  channel: IChannel;
  otp?: string;
}
