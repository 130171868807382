import {
  IIntermediateValidatorAction,
  INTERMEDIATE_VALIDATOR_CANCEL_FORWARDING_TYPE,
  INTERMEDIATE_VALIDATOR_CANCEL_REJECTION_TYPE,
  INTERMEDIATE_VALIDATOR_FORWARDING_TYPE,
  INTERMEDIATE_VALIDATOR_REJECTED_TYPE,
  INTERMEDIATE_VALIDATOR_START_REJECTION_TYPE
} from '../actions/IIntermediateValidatorAction';
import { IIntermediateValidatorState } from '../states/IIntermediateValidatorState';

export function currentIntermediateValidatorReducer(state: IIntermediateValidatorState = { rejecting: false, rejected: false, forwarding: false, forwarded: false },
                                                    action: IIntermediateValidatorAction): IIntermediateValidatorState {
  switch (action.type) {
    case INTERMEDIATE_VALIDATOR_REJECTED_TYPE:
      return Object.assign({}, state, { rejected: true, rejecting: false });
    case INTERMEDIATE_VALIDATOR_START_REJECTION_TYPE:
    case INTERMEDIATE_VALIDATOR_CANCEL_REJECTION_TYPE:
      return Object.assign({}, state, { rejecting: action.rejecting });
    case INTERMEDIATE_VALIDATOR_FORWARDING_TYPE:
    case INTERMEDIATE_VALIDATOR_CANCEL_FORWARDING_TYPE:
      return Object.assign({}, state, { forwarding: action.forwarding });
  }
  return state;
}
