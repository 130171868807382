import {WidgetConfiguration} from '@sgwt-widget/core';
import {ISignatureCounterpart} from '../domain/ISignatureCounterpart';
import {ITransaction} from '../domain/ITransaction';
import {ajaxCall} from "./AjaxService";

export async function rejectValidation(widgetConfiguration: WidgetConfiguration, transactionId: number): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "POST",
    responseType: "json",
    crossDomain: true,
    url: `${serviceUrl}/rest/transaction/${transactionId}/intermediate-validator/reject`,
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}

export async function forwardTransactionToSignatories(widgetConfiguration: WidgetConfiguration, transactionId: number, updatedCounterparts: ISignatureCounterpart[]): Promise<ITransaction> {
  const serviceUrl = (await widgetConfiguration.myConfiguration<ISignatureConfig>()).esignServiceUrl;
  if (!serviceUrl) {
    return Promise.reject("Missing E-Sign service URL");
  }
  return ajaxCall({
    method: "POST",
    responseType: "json",
    crossDomain: true,
    headers: { "Content-Type": "application/json" },
    url: `${serviceUrl}/rest/transaction/${transactionId}/forward-to-signatories`,
    body: updatedCounterparts
  }, widgetConfiguration)
    .map(response => response.response)
    .toPromise();
}
