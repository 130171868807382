import "./Spinner.scss";

import { Component, h } from "preact";
import { css } from "../../styles";
import { SpinnerWidget } from "./SpinnerWidget";

interface ISpinnerProps {
  loading: boolean;
  size?: number
}

export class Spinner extends Component<ISpinnerProps, {}> {
  public render(): JSX.Element {
    return (
      <div className={this.props.loading ? "spinner-box" : css("d-none")} style={"height: auto"}>
        <SpinnerWidget size={this.props.size}></SpinnerWidget>
      </div>
    );
  }
}
