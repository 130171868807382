import { ITransactionCollateralOptions } from "../domain/ITransaction";

export function getSelelectedSpiderCollateralOptionOrder(selectedCollateralOption: ITransactionCollateralOptions): number {
  return Object.keys(ITransactionCollateralOptions).indexOf(selectedCollateralOption);
}

function isSelectedSpiderCollateralOptionInConsent(
  consents: string[],
  selectedCollateralOption: ITransactionCollateralOptions,
  collateralList: string[]
): boolean {
  const selectedSpiderCollateralOptionOrder = getSelelectedSpiderCollateralOptionOrder(selectedCollateralOption);
  const availableSpiderCollateralOption = collateralList[selectedSpiderCollateralOptionOrder];
  return consents.filter(consent => consent === availableSpiderCollateralOption).length > 0;
}

export function isSpiderCollateralChecked(
  consents: string[] | undefined,
  selectedCollateralOption: ITransactionCollateralOptions | undefined,
  collateralList: string[]
): boolean {
  if(selectedCollateralOption == null) {
    return true
  }
  return !consents
  ? false
  : isSelectedSpiderCollateralOptionInConsent(consents, selectedCollateralOption, collateralList)
}