import {Action} from "redux";

export const VALIDATE_TRANSACTION_TYPE = "VALIDATE_TRANSACTION";

export interface IValidatorAction extends Action<string> {
  isSignatureValidated: boolean;
  openValidationModal: boolean;
}

export function confirmValidation(openModal: boolean, acceptValidation: boolean): IValidatorAction {
  return {
    isSignatureValidated: acceptValidation,
    openValidationModal: openModal,
    type: VALIDATE_TRANSACTION_TYPE
  };
}
