import {Component, h} from "preact";
import {Text} from "preact-i18n";

import {ICounterpartSignatory} from "../../../../../common/domain/ICounterpartSignatory";
import {ISignatureCounterpart} from "../../../../../common/domain/ISignatureCounterpart";
import {css} from "../../../../styles";
import {IUser} from "../../../../../common/domain/User";

interface IState {
  counterparts: ISignatureCounterpart[];
}

export interface ISignatoryComponentProps {
  counterpart: ISignatureCounterpart;
  language: string;
  transactionId: number;
  currentUser?: IUser;
  esignBaseUrl: string;
}

export class SignatoryComponent extends Component<ISignatoryComponentProps,
  IState> {
  public static readonly CORPORATE = "CORPORATE";
  public static readonly INTERNAL = "INTERNAL";

  public openNotification() {
    window.open(
      `${this.props.esignBaseUrl}/#/view/${this.props.transactionId}/notification`,
      'popup',
      'width=650,height=600,left=160,top=180'
    );
  }

  public renderNotification(signatory: ICounterpartSignatory): JSX.Element {
    if (this.props.currentUser && this.props.currentUser.username === signatory.identifier) {
      return <em className="material-icons pointer" onClick={() => this.openNotification()}>
        mail_outline
      </em>
    }
    return <div/>
  }

  public buildSignatoryName(signatory: ICounterpartSignatory): JSX.Element {
    if (SignatoryComponent.CORPORATE === this.props.counterpart.type) {
      return <h5 class={css("font-weight-bold")}>{signatory.identifier}</h5>;
    }
    return (
      <h5 class={css("font-weight-bold")}>
        {signatory.firstname} {signatory.lastname.toUpperCase()} {this.renderNotification(signatory)}
      </h5>
    );
  }

  public buildSignatoryInfo(signatory: ICounterpartSignatory): JSX.Element {
    if (SignatoryComponent.INTERNAL === this.props.counterpart.type) {
      return (
        <span className={css("badge badge-discreet-info badge-prepend-square")}
          style={"white-space: break-spaces !important; text-align: left !important;"}>
          {signatory.organization}
        </span>
      );
    }
    return <p>{this.props.counterpart.name}</p>;
  }

  public displaySignatories(): JSX.Element[] {
    return this.props.counterpart.counterpartSignatories.map(
      (signatory: ICounterpartSignatory) => (
        <article className={css("mb-3")}>
          {this.buildSignatoryName(signatory)}
          {this.buildSignatoryInfo(signatory)}
        </article>
      )
    );
  }

  public displayCounterpartType(counterpart: ISignatureCounterpart): JSX.Element {
    if (
      counterpart.order === 1 &&
      (counterpart.type === SignatoryComponent.CORPORATE ||
        counterpart.type === SignatoryComponent.INTERNAL)
    ) {
      return (
        <Text id={this.props.language + ".counterpart.sg_signatories"}>
          SG Signatory
        </Text>
      );
    }
    return (
      <Text
        id={this.props.language + ".counterpart.signatories"}
        fields={{name: counterpart.name}}>
        <p>
          {counterpart.name} Signatory
        </p>
      </Text>
    );
  }

  public render(): JSX.Element {
    return (
      <section>
        <h6 class={css("text-secondary")}>{this.displayCounterpartType(this.props.counterpart)}</h6>
        {this.displaySignatories()}
      </section>
    );
  }
}
