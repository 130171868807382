import { Action } from "redux";
import { IChannel } from "../../common/domain/IChannel";
import { IOtp, OtpMode } from "../../common/domain/IOtp";
import { ISignatureWorkflow } from "../../common/domain/ISignatureWorkflow";

export const ACCEPT_CONSENT_TYPE = "ACCEPT_CONSENT";
export const REQUEST_SIGNATURE_TYPE = "REQUEST_SIGNATURE";
export const ALL_DOCUMENTS_READ_TYPE = "ALL_DOCUMENTS_READ";
export const SET_CHANNEL_TYPE = "SET_CHANNEL";
export const SUBMIT_OTP_TYPE = "SUBMIT_OTP";
export const TRANSACTION_SIGNED_TYPE = "TRANSACTION_SIGNED";
export const CHECK_PHONE_NUMBER_TYPE = "CHECK_PHONE_NUMBER";
export const PHONE_NUMBER_CONFIRMATION_TYPE = "PHONE_NUMBER_CONFIRMATION";
export const REQUEST_SIGNATURE_WITHOUT_OTP_TYPE = "REQUEST_SIGNATURE_WITHOUT_OTP";
export const ERROR_CONSENT_NOT_CHECK_TYPE = "ERROR_CONSENT_NOT_CHECK";
export const CANCEL_SIGNATURE_TYPE = "CANCEL_SIGNATURE";
export const UPDATE_SIGNATURE_STEP = "UPDATE_STEP";
export const SET_SIGNATURE_WORKFLOW = "SET_SIGNATURE_WORKFLOW";
export const UPDATE_DOCUMENTS_VALIDATION = "UPDATE_DOCUMENTS_VALIDATION";
export const IS_OTP_VALID = "IS_OTP_VALID";
export const INIT_WORKFLOW = "INIT_WORKFLOW";
export const SET_CONSENT = "SET_CONSENT";
export const IS_PUBLIC_SIGNATORY = "IS_PUBLIC_SIGNATORY";
export const SET_OTP_MODE = "SET_OTP_MODE";
export const SET_CERTIFICATE_PENDING = "SET_CERTIFICATE_PENDING";

export interface ISignatureAction extends Action<string> {
  allDocumentsRead?: boolean;
  consent?: string;
  otpMode?: OtpMode;
  channel?: IChannel;
  otp?: IOtp;
  signed?: boolean;
  checkPhoneNumber?: boolean;
  phoneNumberIsValid?: boolean;
  errorConsent?: boolean;
  signing?: boolean;
  step?: number;
  signatureWorkflow?: ISignatureWorkflow;
  documentsValidation?: boolean;
  isOTPValid?: boolean;
  isPublicSignatory?: boolean;
  isCertificatePending?: boolean;
}

export function allDocumentsRead(areRead: boolean): ISignatureAction {
  return {
    allDocumentsRead: areRead,
    type: ALL_DOCUMENTS_READ_TYPE,
  };
}

export function acceptConsent(consent: string): ISignatureAction {
  return {
    consent,
    type: ACCEPT_CONSENT_TYPE,
  };
}

export function sign(otpMode: OtpMode, phoneNumberIsValid: boolean): ISignatureAction {
  return {
    otpMode,
    phoneNumberIsValid,
    type: REQUEST_SIGNATURE_TYPE,
  };
}

export function setChannel(channel: IChannel): ISignatureAction {
  return {
    channel,
    type: SET_CHANNEL_TYPE,
  };
}

export function setOtp(otp: IOtp): ISignatureAction {
  return {
    otp,
    type: SUBMIT_OTP_TYPE,
  };
}

export function transactionSigned(isSigned: boolean): ISignatureAction {
  return {
    signed: isSigned,
    type: TRANSACTION_SIGNED_TYPE,
  };
}

export function checkPhoneNumber(): ISignatureAction {
  return {
    checkPhoneNumber: true,
    type: CHECK_PHONE_NUMBER_TYPE,
  };
}

export function validatePhoneNumber(isValid: boolean): ISignatureAction {
  return {
    checkPhoneNumber: false,
    phoneNumberIsValid: isValid,
    type: PHONE_NUMBER_CONFIRMATION_TYPE,
  };
}

export function signWithoutOtp(signing: boolean): ISignatureAction {
  return {
    otpMode: OtpMode.NONE,
    signing,
    type: REQUEST_SIGNATURE_WITHOUT_OTP_TYPE,
  };
}

export function errorConsent(isInError: boolean): ISignatureAction {
  return {
    errorConsent: isInError,
    type: ERROR_CONSENT_NOT_CHECK_TYPE,
  };
}

export function cancelSignature(): ISignatureAction {
  return {
    type: CANCEL_SIGNATURE_TYPE
  }
}

export function updateStep(step: number): ISignatureAction {
  return {
    step,
    type: UPDATE_SIGNATURE_STEP,
  };
}

export function setSignatureWorkflow(signatureWorkflow: ISignatureWorkflow): ISignatureAction {
  return {
    signatureWorkflow,
    type: SET_SIGNATURE_WORKFLOW,
  };
}

export function setDocumentsValidation(documentsValidation: boolean): ISignatureAction {
  return {
    documentsValidation,
    type: UPDATE_DOCUMENTS_VALIDATION,
  };
}

export function setIsOTPValid(isOTPValid: boolean): ISignatureAction {
  return {
    isOTPValid,
    type: IS_OTP_VALID,
  };
}

export function initWorkflow(): ISignatureAction {
  return {
    type: INIT_WORKFLOW
  }
}

export function setConsent(step: number, consent: string): ISignatureAction {
  return {
    step,
    consent,
    type: SET_CONSENT
  }
}

export function setIsPublicSignatory(isPublicSignatory: boolean): ISignatureAction {
  return {
    isPublicSignatory,
    type: IS_PUBLIC_SIGNATORY
  }
}

export function setOTPMode(otpMode: OtpMode): ISignatureAction {
  return {
    otpMode,
    type: SET_OTP_MODE
  }
}

export function setIsCertificatePending(isCertificatePending: boolean): ISignatureAction {
  return {
    isCertificatePending,
    type: SET_CERTIFICATE_PENDING
  }
}